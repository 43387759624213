import React from "react";
import PropTypes from "prop-types";

const FormFilter = ({label, children}) => (
    <div className="col-12 col-lg-auto d-lg-flex align-items-lg-center">
        <div className="form-group">
            {label && <label>{label}:</label>}
            {children}
        </div>
    </div>
);

FormFilter.propTypes = {
    label: PropTypes.string,
    children: PropTypes.node
};

export default FormFilter;
