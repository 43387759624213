import React from "react";
import {Alert, Form} from "react-bootstrap";
import {useCreateBooking} from "../../../util/bookings";
import CreateBookingSection from "../CreateBookingSection";

import "./styles.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";

const SelectContainerDetails = () => {

    const {bookingState, setRegoNo, setISOCode, setLoadStatus} = useCreateBooking();

    return (
        <CreateBookingSection title="Container Details">
            <div className="row">
                {
                    bookingState.isocode_options.length && bookingState.isocode_options.length > 1 ?
                        <div className="col-12">
                            <Alert variant="warning">
                                More than one ISO code available. Select one before saving this booking.
                            </Alert>
                        </div>
                        :
                        null
                }
                <div className="col-6 col-sm-4 col-md-6 col-lg-4">
                    <Form.Group controlId="isocode">
                        <label>ISO code:</label>
                        {
                            bookingState.isocode_options.length ?
                                <Form.Control
                                    name="isocode"
                                    value={bookingState.isocode}
                                    isInvalid={!!bookingState.errors.isocode}
                                    onChange={(event) => setISOCode(event.target.value)}
                                    as="select">
                                    {bookingState.isocode_options.length > 1 && <option value="">Select...</option>}
                                    {
                                        bookingState.isocode_options.map((iso, i) => (
                                            <option key={`iso-option-${i}`} value={iso}>{iso}</option>
                                        ))
                                    }
                                </Form.Control>
                                :
                                <Alert variant="warning">
                                    {
                                        bookingState.direction === "IN" ?
                                            "Added automatically when booking validated"
                                            :
                                            `Enter release${bookingState.branchcode === "PC" ? "/container" : ""} number`
                                    }
                                </Alert>

                        }
                        {
                            bookingState.errors.isocode &&
                            <Form.Control.Feedback type="invalid">
                                <FontAwesomeIcon icon={faExclamationTriangle}/> {bookingState.errors.isocode}
                            </Form.Control.Feedback>
                        }
                    </Form.Group>
                </div>
                {
                    bookingState.direction === "OUT" &&
                    <div className="col-6 col-sm-4 col-md-6 col-lg-4">
                        <Form.Group controlId="loadstatus">
                            <label>Load status:</label>
                            {
                                bookingState.loadstatus_options.length ?
                                    <Form.Control
                                        name="loadstatus"
                                        value={bookingState.loadstatus}
                                        isInvalid={!!bookingState.errors.loadstatus}
                                        onChange={(event) => setLoadStatus(event.target.value)}
                                        as="select">
                                        {bookingState.loadstatus_options.length > 1 && <option value="">Select...</option>}
                                        {
                                            bookingState.loadstatus_options.map((status, i) => (
                                                <option key={`loadstatus-option-${i}`} value={status}>{status === "MT" ? "Empty" : "Full"}</option>
                                            ))
                                        }
                                    </Form.Control>
                                    :
                                    <Alert variant="warning">
                                        {
                                            bookingState.direction === "IN" ?
                                                `Enter container number`
                                                :
                                                `Enter release${bookingState.branchcode === "PC" ? "/container" : ""} number`
                                        }
                                    </Alert>
                            }
                            {
                                bookingState.errors.loadstatus &&
                                <Form.Control.Feedback type="invalid">
                                    <FontAwesomeIcon icon={faExclamationTriangle}/> {bookingState.errors.loadstatus}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                    </div>
                }
                <div className="col-6 col-sm-4 col-md-6 col-lg-4">
                    <Form.Group controlId="regono">
                        <label>Rego number:</label>
                        <Form.Control
                            name="regono"
                            isInvalid={!!bookingState.errors.regono}
                            value={!bookingState.regono ? "" : bookingState.regono}
                            onChange={(event) => setRegoNo(event.target.value)}/>
                        {
                            bookingState.errors.regono &&
                            <Form.Control.Feedback type="invalid">
                                <FontAwesomeIcon icon={faExclamationTriangle}/> {bookingState.errors.regono}
                            </Form.Control.Feedback>
                        }
                    </Form.Group>
                </div>
            </div>
        </CreateBookingSection>
    );
};

export default SelectContainerDetails;
