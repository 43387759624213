import React, {useState} from "react";
import "./styles.scss";
import PageTitle from "../../components/layout/PageTitle";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {Alert, Form, FormControl} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";
import {useBranchesData} from "../../util/data-hooks";
import {APICall} from "../../util/api";
import axios from "axios";
import Loader from "../../components/layout/Loader";
import FormButton from "../../components/forms/FormButton";
import {useAuth} from "../../util/auth";

const ImportPendingContainersSchema = yup.object().shape({
    branchcode: yup.string().required("Depot is required"),
    containers: yup.string().required("Container numbers are required")
});

const ImportPendingContainersPage = () => {

    const {user} = useAuth(),
        [branchesLoading, branchesData, branchesError] = useBranchesData(true),
        {register, handleSubmit, errors, setValue} = useForm({validationSchema: ImportPendingContainersSchema}),
        [formSubmitting, setFormSubmitting] = useState(false),
        [containerErrors, setContainerErrors] = useState(null),
        [containerImports, setContainerImports] = useState(null);

    const onSubmit = data => {
        setFormSubmitting(true);
        setContainerErrors(null);
        setContainerImports(null);

        let containers = data.containers
            .split("\n")
            .map(item => ({containerno: item.trim()}))
            .filter(item => item.containerno !== "");

        APICall({
            method: "post",
            url: `?MessageType=ImportPendingContainers`,
            data: {id: user.id, branchcode: data.branchcode, containers}
        })
            .then(response => {

                const imported = [];
                const errors = [];

                containers.forEach(container => {
                    const findContainer = response.data.containers.find(item => item.containerno === container.containerno);
                    if (!!findContainer && !!findContainer.error) {
                        errors.push({...findContainer});
                    } else {
                        imported.push({...container});
                    }
                });

                if (errors.length) setContainerErrors(errors);
                if (imported.length) setContainerImports(imported);

                setValue("containers", containers
                    .filter(item => !imported.find(find_item => find_item.containerno === item.containerno))
                    .map(item => item.containerno).join("\n"));

                setFormSubmitting(false);
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    setFormSubmitting(false);
                }
            });
    };

    return (
        <>
            <PageTitle title="Import Pending Containers"/>
            <div className="container-fluid">
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="row">
                        <div className="col-12">
                            <p>To import pending containers, select the depot from the drop down, and then paste a list of containers into the box.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {
                                !!containerImports &&
                                <Alert variant="success">
                                    <p><strong>Imported Successfully</strong></p>
                                    <ul>
                                        {
                                            containerImports.map((container, i) => <li key={`import-${i}`}>{container.containerno}</li>)
                                        }
                                    </ul>
                                </Alert>
                            }
                            {
                                !!containerErrors &&
                                <Alert variant="danger">
                                    <p><strong>Import Errors</strong></p>
                                    <ul>
                                        {
                                            containerErrors.map((container, i) => <li key={`error-${i}`}>{container.error}</li>)
                                        }
                                    </ul>
                                </Alert>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            {
                                branchesLoading ?
                                    <Loader/>
                                    :
                                    branchesError ?
                                        <Alert variant="danger">{branchesError}</Alert>
                                        :
                                        <Form.Group controlId="branchcode">
                                            <Form.Label>Depot</Form.Label>
                                            <FormControl
                                                ref={register}
                                                as="select"
                                                name="branchcode"
                                                isInvalid={!!errors.branchcode}
                                                defaultValue={""}>
                                                <option value="">Select depot</option>
                                                {
                                                    branchesData.map((branch, i) => (
                                                        <option key={`branch-${i}`}
                                                                value={branch.branchcode}>
                                                            {branch.name}
                                                        </option>
                                                    ))
                                                }
                                            </FormControl>
                                            {
                                                !!errors.branchcode &&
                                                <Form.Control.Feedback type="invalid">
                                                    <FontAwesomeIcon
                                                        icon={faExclamationTriangle}/> {errors.branchcode.message}
                                                </Form.Control.Feedback>
                                            }
                                        </Form.Group>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                            <Form.Group controlId="containers">
                                <Form.Label>Container numbers</Form.Label>
                                <FormControl
                                    ref={register}
                                    as="textarea"
                                    rows="10"
                                    name="containers"
                                    isInvalid={!!errors.containers}
                                    defaultValue={""}/>
                                {
                                    !!errors.containers &&
                                    <Form.Control.Feedback type="invalid">
                                        <FontAwesomeIcon
                                            icon={faExclamationTriangle}/> {errors.containers.message}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </div>
                    </div>

                    <div className="form-buttons">
                        <div className="row">
                            <div className="col form-buttons-left">
                                <FormButton type="submit" variant="primary"
                                            loading={formSubmitting}>Import</FormButton>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </>
    );
};

export default ImportPendingContainersPage;
