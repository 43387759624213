import React, {useEffect} from 'react';
import AppLogo from '../../components/layout/AppLogo';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    Alert,
    Card,
    Form,
    FormControl,
    InputGroup
} from 'react-bootstrap';

import FormButton from '../../components/forms/FormButton';

import './styles.scss';
import {useForm} from "react-hook-form";
import {
    faKey,
    faUser
} from "@fortawesome/pro-regular-svg-icons";
import {getConfigValue} from "../../util/config";
import history from "../../util/history";
import {useAuth} from "../../util/auth";
import {application} from "../../util/application";


const Login = () => {

    const {login, loginLoading, loginError} = useAuth();

    const {register, handleSubmit} = useForm();

    const onSubmit = data => {
        login(data);
    };

    useEffect(() => {
        const jwt = getConfigValue('auth.jwt');
        if (jwt) history.push('/');
    }, []);

    return (
        <>
            <div className="minimal-layout login">
                <div className="container d-flex h-100 align-items-center">
                    <div className="login-container">
                        <div className="minimal-layout-header">
                            <AppLogo subtitle={application.application_subtitle} subtitleMobile={application.application_subtitle_mobile} />
                        </div>
                        <Card>
                            <Card.Body>

                                <h1>Login to your account</h1>

                                {
                                    !!loginError &&
                                    <Alert variant="danger">{loginError}</Alert>
                                }

                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="form-group">
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="login-username">
                                                    <FontAwesomeIcon icon={faUser} />
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                ref={register}
                                                name="login"
                                                placeholder="Username"
                                                aria-label="Username"
                                                aria-describedby="login-username"
                                            />
                                        </InputGroup>
                                    </div>

                                    <div className="form-group">
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="login-password"><FontAwesomeIcon
                                                    icon={faKey} /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                ref={register}
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                aria-label="Password"
                                                aria-describedby="login-password"
                                            />
                                        </InputGroup>
                                    </div>

                                    <div className="form-group">
                                        <FormButton type="submit"
                                                    variant="primary"
                                                    block
                                                    loading={loginLoading}>Login</FormButton>
                                    </div>

                                </form>

                            </Card.Body>
                        </Card>

                        {/*<div className="login-footer">*/}
                        {/*    <p>CONTAINERLINK is an online container booking system for pickup & delivery to/from Container Depots. Only authorised users may access this site.</p>*/}
                        {/*    <p>If you would like to use this system please call 1500 555 555 to become a Registered User.</p>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
