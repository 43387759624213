import React from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";

import './styles.scss';
import FormButton from "../../forms/FormButton";

const ConfirmModal = (props) => {

    return (
        <Modal
            show={props.show}
            size="lg"
            backdrop="static"
            centered>
            <Modal.Body>
                {
                    !!props.alert &&
                        <>{props.alert}</>
                }
                {props.message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={props.cancelVariant} onClick={props.closeModal}>{props.cancelText}</Button>
                <FormButton loading={props.confirmLoading} variant={props.confirmVariant} onClick={props.onConfirm}>{props.confirmText}</FormButton>
            </Modal.Footer>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    show: PropTypes.bool,
    alert: PropTypes.node,
    onConfirm: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    cancelText: PropTypes.string,
    cancelVariant: PropTypes.string,
    confirmText: PropTypes.string,
    confirmVariant: PropTypes.string,
    confirmLoading: PropTypes.bool.isRequired
};

ConfirmModal.defaultProps = {
    show: false,
    cancelText: 'Cancel',
    cancelVariant: 'light',
    confirmText: 'Confirm',
    confirmVariant: 'primary',
    confirmLoading: false
};

export default ConfirmModal;
