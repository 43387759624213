import React from "react";
import {Switch, Route, Router} from 'react-router-dom';
import "./styles.scss";
import MainLayout from "../../components/layout/MainLayout";
import Login from "../login";
import Logout from "../logout";

import {routes} from '../../routes';

import history from "../../util/history";
import {ProvideAuth} from "../../util/auth";

const App = () => (
    <Router history={history}>
        <ProvideAuth>
            <Switch>
                <Route path="/login" exact render={() => <Login />} />
                <Route path="/logout" exact render={() => <Logout />} />
                <Route path="*" render={props => <MainLayout {...props} routes={routes} />} />
            </Switch>
        </ProvideAuth>
    </Router>
);

export default App;
