import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {enableES5} from "immer";

import React from "react";
import {render} from "react-dom";
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";

import './scss/index.scss';

enableES5();

render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
