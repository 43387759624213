import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {getUserMenuItems, menu} from '../../../util/menu';
import {useLocation} from 'react-router-dom';

import './styles.scss';
import {useAuth} from "../../../util/auth";

const Sidebar = ({sidebarOpen, closeSidebar}) => {

    const location = useLocation();

    const {user} = useAuth();
    const user_menu = getUserMenuItems(menu, user);

    return (
        <>
            <nav className={`sidebar ${sidebarOpen ? ' sidebar-open' : ''}`}>
                <ul className="nav flex-column">
                    {
                        user_menu.map((item, i) => (
                            item.type === 'group' && item.children
                                ?
                                <React.Fragment key={`sidebar-item-group-${i}`}>
                                    <li className={`nav-item nav-item-group`}>
                                        <span>{item.title}</span>
                                    </li>
                                    {
                                        item.children.map((nav_item, j) => (
                                            <li key={`sidebar-item-${i}-${j}`}
                                                className={`nav-item ${nav_item.route.path === location.pathname ? 'active' : ''}`}>
                                                <Link className="nav-link" to={nav_item.route.path} onClick={closeSidebar}>{nav_item.title}</Link>
                                            </li>
                                        ))
                                    }
                                </React.Fragment>
                                :
                                <li key={`sidebar-item-${i}`}
                                    className={`nav-item ${item.route.path === location.pathname ? 'active' : ''}`}>
                                    <Link className="nav-link" to={item.route.path} onClick={closeSidebar}>{item.title}</Link>
                                </li>
                        ))
                    }
                </ul>
            </nav>
            <div className={`sidebar-overlay ${sidebarOpen ? ' sidebar-open' : ''}`} onClick={closeSidebar}/>
        </>
    );
};

Sidebar.propTypes = {
    sidebarOpen: PropTypes.bool.isRequired,
    closeSidebar: PropTypes.func.isRequired
};

export default Sidebar;
