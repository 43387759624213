import React, {useState, useContext, createContext, useCallback} from "react";
import {clearConfig, getConfigValue, setConfigValue} from "./config";
import history from "./history";
import {APICall} from "./api";
import _ from "lodash";
import {ticksToSeconds} from "./date";

const authContext = createContext();

export function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [user, setUser] = useState(null),

          [checkAuthLoading, setCheckAuthLoading] = useState(false),
          [checkAuthError, setCheckAuthError] = useState(null),

          [loginLoading, setLoginLoading] = useState(false),
          [loginError, setLoginError] = useState(null);

    const login = (data) => {
        setLoginLoading(true);

        APICall({
            method: 'post',
            url: '?MessageType=Login',
            authorised: false,
            data: {login: data.login, password: data.password}
        })
        .then(response => {
            const userData = _.omit(response.data, ['token', 'tokenexpiry', 'refreshtoken']);

            setConfigValue('auth.jwt', response.data.token);
            setConfigValue('auth.jwt_expiry', ticksToSeconds(response.data.tokenexpiry));
            setConfigValue('auth.refresh_token', response.data.refreshtoken);
            setConfigValue('auth.user', {
                ...userData,
                carriercode: userData.carrierid
            });
            setConfigValue('auth.user.login', data.login);

            setUser(userData);
            setLoginError(null);
            setLoginLoading(false);

            history.push('/');
        })
        .catch(err => {
            if (_.get(err, 'response.status', 400) !== 403) {
                setLoginError('Server error - if the issue continues, please contact Port Otago directly');
            } else {
                setLoginError('Invalid login details');
            }
            setLoginLoading(false);
        });
    };

    const userHasPermission = useCallback((permission) => {
        return _.get(user, `access.${permission}`, false);
    }, [user]);

    const logout = () => {
        clearConfig();
        history.push('/login');
    };

    const checkAuth = (redirectURL = false) => {

        setCheckAuthLoading(true);

        const jwt = getConfigValue('auth.jwt', false);

        if (!jwt) {
            clearConfig();
            history.push('/login');
            return;
        }

        const user_id = getConfigValue('auth.user.id');
        const user_login = getConfigValue('auth.user.login');

        APICall({
            method: 'post',
            url: '?MessageType=GetUserDetails',
            data: {id: user_id, userlogin: user_login}
        })
        .then(response => {
            setUser(response.data);
            setCheckAuthLoading(false);

            if (redirectURL) {
                history.push(redirectURL);
            }
        })
        .catch(err => {
            const message = _.get(err.response, 'data.Message', 'Server error, could not authorise user');
            setCheckAuthError(message);
            setCheckAuthLoading(false);
        });
    };

    return {
        user,
        userHasPermission,
        checkAuthLoading,
        checkAuthError,
        loginLoading,
        loginError,
        login,
        logout,
        checkAuth
    };
}
