import { useEffect } from 'react';
import {useAuth} from "../../util/auth";

const Logout = () => {
    const {logout} = useAuth();

    useEffect(() => {
        logout();
    }, [logout]);

    return null;
};

export default Logout;
