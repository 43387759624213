import _ from 'lodash';

const config_key = 'config';

const getConfig = () => {
    const config = _.defaultTo(localStorage.getItem(config_key), '{}');
    return JSON.parse(config);
};

const setConfig = (config) => {
    localStorage.setItem(config_key, JSON.stringify(config));
};

export const clearConfig = () => localStorage.removeItem(config_key);

export const getConfigValue = (path, defaultTo = undefined) => _.get(getConfig(), path, defaultTo);

export const setConfigValue = (path, value) => {
    const config = _.set({...getConfig()}, path, value);
    setConfig(config);
};
