import {
    useEffect,
    useState
} from 'react';
import {
    APICall,
    APICancelRequest,
    APIError
} from "./api";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import {dateToTicks} from "./date";
import {application} from "./application";
import {useAuth} from "./auth";


export function usePendingContainersData(branchcode) {
    const {user} = useAuth(),
        [containersLoading, setContainersLoading] = useState(true),
        [containersError, setContainersError] = useState(null),
        [containersData, setContainersData] = useState(null);

    useEffect(() => {
        const [cancel_token, cancelRequest] = APICancelRequest();

        APICall({
            method: 'post',
            url: '?MessageType=GetPendingContainers',
            data: {id: user.id, branchcode},
            cancel_token
        })
            .then(response => {
                setContainersData(_.get(response, 'data.containers', []).map(container => container.containerno));
                setContainersLoading(false);
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    setContainersError(APIError(err.response));
                    setContainersLoading(false);
                    setContainersData(null);
                }
            });

        return () => cancelRequest('Cancelled request');
    }, [user.id, branchcode]);

    return [containersLoading, containersData, containersError];
}


export function useBranchesData(userBranchesOnly = false) {
    const {user} = useAuth(),
          [branchesLoading, setBranchesLoading] = useState(true),
          [branchesError, setBranchesError] = useState(null),
          [branchesData, setBranchesData] = useState(null);

    useEffect(() => {
        const [cancel_token, cancelRequest] = APICancelRequest();

        APICall({
            method: 'post',
            url: '?MessageType=GetBranches',
            data: {id: user.id},
            cancel_token
        })
            .then(response => {
                let data = _.get(response, 'data.branches', []);

                if (userBranchesOnly) data = data.filter(branch => !!user.userbranches.find(userbranch => userbranch.branchcode === branch.branchcode));

                data = data.map(branch => ({
                    ...branch,
                    name: application.branch_names[branch.branchcode]
                }));

                setBranchesData(data);
                setBranchesLoading(false);
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    setBranchesError(APIError(err.response));
                    setBranchesLoading(false);
                    setBranchesData(null);
                }
            });

        return () => cancelRequest('Cancelled request');
    }, [user.id, userBranchesOnly, user.userbranches]);

    return [branchesLoading, branchesData, branchesError];
}

export function getBranchName(branchesData, branchcode) {
    if (!!branchcode) {
        const element = branchesData.find(branch => branch.branchcode === branchcode);
        return element ? element.name : branchcode;
    }
    return '';
}


export function useCarriersData() {
    const {user} = useAuth(),
          [carriersLoading, setCarriersLoading] = useState(true),
          [carriersError, setCarriersError] = useState(null),
          [carriersData, setCarriersData] = useState(null);

    useEffect(() => {
        const [cancel_token, cancelRequest] = APICancelRequest();

        APICall({
            method: 'post',
            url: '?MessageType=GetCarriers',
            data: {id: user.id},
            cancel_token
        })
            .then(response => {
                setCarriersData(_.get(response, 'data.carriers', []));
                setCarriersLoading(false);
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    setCarriersError(APIError(err.response));
                    setCarriersLoading(false);
                    setCarriersData(null);
                }
            });

        return () => cancelRequest('Cancelled request');
    }, [user.id]);

    return [carriersLoading, carriersData, carriersError];
}

export function getCarrierName(carriersData, carriercode) {
    if (!!carriercode) {
        const element = carriersData.find(carrier => carrier.carriercode === carriercode);
        return element ? element.name : carriercode;
    }
    return '';
}



export function useBookingSlotsData(branchcode, bookingDate) {
    const {user} = useAuth(),
          [bookingsLoading, setBookingsLoading] = useState(true),
          [bookingsError, setBookingsError] = useState(null),
          [bookingsData, setBookingsData] = useState(null);

    useEffect(() => {
        const [cancel_token, cancelRequest] = APICancelRequest();

        function availableDateRequests() {
            const available_dates = [];
            let day_count = 0;

            while (available_dates.length < 5) {
                const date = moment().add(day_count, 'days').startOf('day');
                const maxday = branchcode === 'PC' ? 7 : 6;
                if (date.isoWeekday() < maxday) {
                    available_dates.push(date);
                }
                day_count++;
            }

            return available_dates.map(date => ({
                date,
                request: APICall({
                    method: 'post',
                    url: '?MessageType=GetSlots',
                    data: {id: user.id, dateticks: dateToTicks(date), branchcode},
                    cancel_token
                })
            }));
        }

        if (!bookingDate) {

            setBookingsLoading(true);

            if (branchcode) {
                const requests = availableDateRequests();

                Promise.all([...requests.map(r => r.request)]).then(response => {
                    const date_options = response.map((res, r) => ({
                        date: {
                            moment: requests[r].date,
                            base: requests[r].date.format('DD-MM-YYYY'),
                            day_name: requests[r].date.format('dddd'),
                            day: requests[r].date.format('Do'),
                            month: requests[r].date.format('MMMM'),
                            year: requests[r].date.format('YYYY'),
                            ticks: dateToTicks(requests[r].date.toDate())
                        },
                        slots: res.data.slots.map(slot => ({...slot, direction: slot.direction.toUpperCase()})),
                        branch_alert: _.get(res, 'data.alert', null)
                    }));

                    setBookingsData(date_options);
                    setBookingsLoading(false);
                })
                .catch(err => {
                    if (!axios.isCancel(err)) {
                        setBookingsError(APIError(err.response));
                        setBookingsLoading(false);
                        setBookingsData(null);
                    }
                });
            }
        }

        return () => cancelRequest('Cancelled request');

    }, [user.id, branchcode, bookingDate]);

    return [bookingsLoading, bookingsData, bookingsError];
}
