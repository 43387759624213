import React from "react";
import {
    Alert,
    Dropdown
} from "react-bootstrap";
import Loader from "../../../components/layout/Loader";
import {useCreateBooking} from "../../../util/bookings";
import CreateBookingSection from "../CreateBookingSection";

import "./styles.scss";
import {application} from "../../../util/application";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";
import PropTypes from "prop-types";
import {getCarrierName} from "../../../util/data-hooks";

const SelectCarrier = ({carriersLoading, carriersData, carriersError}) => {

    const {bookingState, setCarrierCode, requireCarrierSelect} = useCreateBooking();

    return (
        <CreateBookingSection title="Carrier">
            {
                carriersError ?
                    <Alert variant="danger">{application.default_error}</Alert>
                    :
                    carriersLoading ?
                        <Loader align="left" />
                        :
                        <>
                            <Dropdown>
                                <Dropdown.Toggle variant={bookingState.carriercode !== undefined ? 'primary' : 'light'} id="create-booking-depot-dropdown">
                                    {
                                        bookingState.carriercode !== undefined
                                            ?
                                            !carriersLoading && !!carriersData && getCarrierName(carriersData, bookingState.carriercode)
                                            :
                                            'Select carrier'
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        requireCarrierSelect ?
                                            carriersData.map((carrier, i) => (
                                                <Dropdown.Item key={`carrier-dropdown-item-${i}`} onClick={() => setCarrierCode(carrier.carriercode)}>{carrier.name}</Dropdown.Item>
                                            ))
                                            :
                                            <Dropdown.Item>{getCarrierName(carriersData, bookingState.carriercode)}</Dropdown.Item>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className={`invalid-feedback ${!!bookingState.errors.carriercode ? 'd-block' : ''}`}>
                                <FontAwesomeIcon icon={faExclamationTriangle} /> {bookingState.errors.carriercode}
                            </div>
                        </>
            }
        </CreateBookingSection>
    );
};

SelectCarrier.propTypes = {
    carriersLoading: PropTypes.bool.isRequired,
    carriersData: PropTypes.array,
    carriersError: PropTypes.string
};

export default SelectCarrier;
