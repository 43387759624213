import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import FormButton from "../../../components/forms/FormButton";
import {
    Alert,
    Button, Form,
    Modal
} from "react-bootstrap";
import {getBranchName, getCarrierName, usePendingContainersData} from "../../../util/data-hooks";
import Loader from "../../../components/layout/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";
import {useForm} from "react-hook-form";
import {APICall} from "../../../util/api";
import _ from "lodash";
import moment from "moment";
import {ticksToSeconds} from "../../../util/date";
import Autosuggest from 'react-autosuggest';
import {useAuth} from "../../../util/auth";
import BookingStatusBadge from "../../../components/badges/BookingStatusBadge";
import {initialFieldLabels} from "../../../util/bookings";
import {application} from "../../../util/application";

const bookingStatusesForPin = application.bookings_status_types.filter(status => status.showPin).map(status => status.statusCode);

const UpdateBookingModal = ({show, handleClose, booking, branchesLoading, branchesData, carriersLoading, carriersData, setRefreshBookings}) => {
    const {user} = useAuth(),
        [saveLoading, setSaveLoading] = useState(false),
        [saveData, setSaveData] = useState(null),
        [saveErrors, setSaveErrors] = useState(null),
        {register, handleSubmit} = useForm();

    const [containersLoading, containersData, containersError] = usePendingContainersData(show ? booking.branchcode : '');
    const [containerSuggestions, setContainerSuggestions] = useState([]);
    const [containerNumber, setContainerNumber] = useState('');

    const onSuggestionsFetchRequested = ({ value }) => {

        if (containersLoading) {
            setContainerSuggestions([]);
        } else {
            const matches = containersData.filter(container => container.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            setContainerSuggestions(matches);
        }

    };

    const onSuggestionsClearRequested = () => {
        setContainerSuggestions([]);
    };

    const onSubmit = (data) => {
        setSaveLoading(true);

        function processErrors(errors) {
            const fieldErrors = {};
            if (errors.length) {
                for (const error of errors) fieldErrors[error.field] = error.error;
            }
            return fieldErrors;
        }

        APICall({
            method: 'post',
            url: '?MessageType=UpdateBooking',
            data: {
                id: user.id,
                ...data,
                containerno: containerNumber.toUpperCase(),
                bookingnumber: booking.bookingno,
                bookingdateticks: booking.slotdateticks,
                branchcode: booking.branchcode
            }
        })
            .then(response => {
                setSaveData(response.data);
                setRefreshBookings(Date.now());
                setSaveLoading(false);
            })
            .catch(err => {
                setSaveErrors(processErrors(_.get(err, 'response.data.errors', [])));
                setSaveLoading(false);
            });
    };

    const resetAndClose = useCallback(() => {
        setSaveLoading(false);
        setSaveData(null);
        setSaveErrors(null);
        handleClose();
    }, [setSaveLoading, setSaveData, setSaveErrors, handleClose]);

    let errorList = [];

    if (!_.isEmpty(saveErrors)) {
        for (const field in saveErrors) {
            if (saveErrors.hasOwnProperty(field)) errorList.push({field: _.get(initialFieldLabels, field, field), error: saveErrors[field]});
        }
    }

    return (
        <Modal backdrop="static" show={show} onHide={handleClose} className="update-booking-modal">
            <Modal.Header>
                <Modal.Title as="h2">Update Booking</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    {
                        !!saveData ?
                            <>
                                <Alert variant="success">
                                    <p>Booking updated successfully.</p>
                                    <p>
                                        Booking status: <BookingStatusBadge bookingstatus={saveData.status} noshow={booking.noshow} cancelled={booking.cancelled} /><br />
                                        {
                                            bookingStatusesForPin.find(status => status === saveData.status)
                                                && <>Booking PIN: <strong>{booking.bookingnoPin}</strong></>
                                        }
                                    </p>
                                </Alert>
                            </>
                            :
                            !!booking ?
                                <>
                                    <div className="update-booking-modal-summary">
                                        <div className="row">
                                            <div className="col-6">
                                                <p>
                                                    <strong>Depot:</strong> <br />
                                                    {!branchesLoading && !!branchesData && getBranchName(branchesData, booking.branchcode)}
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <p>
                                                    <strong>Carrier:</strong> <br />
                                                    {!carriersLoading && !!carriersData && getCarrierName(carriersData, booking.carriercode)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <p>
                                                    <strong>Date &amp; Slot:</strong> <br />
                                                    {moment.unix(ticksToSeconds(booking.slotdateticks)).format('ddd Do MMM YYYY')} <br />
                                                    {booking.slotdescription}
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <p>
                                                    <strong>Status:</strong> <br />
                                                    <BookingStatusBadge bookingstatus={booking.bookingstatus} noshow={booking.noshow} cancelled={booking.cancelled} />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                {
                                                    booking.containerno !== '' &&
                                                    <p>
                                                        <strong>Container number:</strong> <br />
                                                        {booking.containerno}
                                                    </p>
                                                }
                                                {
                                                    booking.bookingreference !== '' &&
                                                    <p>
                                                        <strong>Reference:</strong> <br />
                                                        {booking.bookingreference}
                                                    </p>
                                                }
                                            </div>
                                            <div className="col-6">
                                                <p>
                                                    <strong>Direction:</strong> <br />
                                                    {booking.slotdirection}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <p>
                                                    <strong>ISO Code:</strong> <br />
                                                    {booking.isocode}
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <p>
                                                    <strong>Load Status:</strong> <br />
                                                    {booking.commodity}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <p>
                                                    <strong>Shipping Line:</strong> <br />
                                                    {booking.shippingoperator}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !!saveErrors &&
                                        <>
                                            <Alert variant="danger" className="form-errors">
                                                <p><strong>Please correct the following errors:</strong></p>
                                                <ul>
                                                    {
                                                        errorList.map(({field, error}, i) => (
                                                            <li key={`error-${i}`}>
                                                                <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                                                                <strong>{field}:</strong>{' '}
                                                                {error}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </Alert>
                                        </>
                                    }

                                    {
                                        booking.bookingstatus === 'PND' && !booking.containerno &&
                                            <div className="row">
                                                <div className="col-12">
                                                    <Form.Group controlId="containerno" style={{position: 'relative'}}>
                                                        <Form.Label>Container number:</Form.Label>
                                                        <input name="containerno" defaultValue={!booking.containerno ? '' : booking.containerno} ref={register} className="d-none" />
                                                        {
                                                            containersLoading ?
                                                                <Loader />
                                                                :
                                                                <Autosuggest
                                                                    suggestions={containersError ? [] : containerSuggestions}
                                                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                                    getSuggestionValue={(suggestion) => suggestion}
                                                                    renderSuggestion={(suggestion) => <span>{suggestion}</span>}
                                                                    inputProps={{
                                                                        placeholder: 'Enter container number',
                                                                        value: containerNumber,
                                                                        onChange: (event, { newValue }) => setContainerNumber(newValue),
                                                                        className: "form-control"
                                                                    }}
                                                                />
                                                        }
                                                        {
                                                            !!saveErrors && !!saveErrors.containerno &&
                                                            <Form.Control.Feedback type="invalid" className={'d-block'}>
                                                                <FontAwesomeIcon icon={faExclamationTriangle} /> {saveErrors.containerno}
                                                            </Form.Control.Feedback>
                                                        }
                                                    </Form.Group>
                                                </div>
                                            </div>
                                    }

                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <Form.Group controlId="regono">
                                                <Form.Label>Rego number:</Form.Label>
                                                <Form.Control
                                                    name="regono"
                                                    ref={register}
                                                    isInvalid={!!saveErrors && !!saveErrors.regono}
                                                    defaultValue={!booking.regono ? '' : booking.regono} />
                                                {
                                                    !!saveErrors && !!saveErrors.regono &&
                                                    <Form.Control.Feedback type="invalid">
                                                        <FontAwesomeIcon icon={faExclamationTriangle} /> {saveErrors.regono}
                                                    </Form.Control.Feedback>
                                                }
                                            </Form.Group>
                                        </div>
                                    </div>

                                    {
                                        booking.slotdirection === 'OUT' &&
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <Form.Label>Door position:</Form.Label>
                                                    <Form.Control
                                                        name="doorposition"
                                                        ref={register}
                                                        defaultValue={booking.doorposition}
                                                        isInvalid={!!saveErrors && !!saveErrors.doorposition}
                                                        as="select">
                                                        <option value="">Select...</option>
                                                        <option value="DR">DR</option>
                                                        <option value="DF">DF</option>
                                                    </Form.Control>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <Form.Label>Pin position:</Form.Label>
                                                    <Form.Control
                                                        name="pinsposition"
                                                        ref={register}
                                                        defaultValue={booking.pinsposition}
                                                        isInvalid={!!saveErrors && !!saveErrors.pinsposition}
                                                        as="select">
                                                        <option value="">Select...</option>
                                                        <option value="FP">FP</option>
                                                        <option value="MP">MP</option>
                                                        <option value="BP">BP</option>
                                                    </Form.Control>
                                                </div>
                                            </div>
                                    }
                                </>
                                :
                                <Loader />
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        !!saveData ?
                            <>
                                <Button variant="light" onClick={resetAndClose}>Close</Button>
                            </>
                            :
                            <>
                                <Button variant="light" disabled={saveLoading} onClick={resetAndClose}>Close</Button>
                                <FormButton type="submit" loading={saveLoading} variant="primary">Update Booking</FormButton>
                            </>
                    }
                </Modal.Footer>
            </form>
        </Modal>
    );
};

UpdateBookingModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    booking: PropTypes.object,
    setRefreshBookings: PropTypes.func,
    carriersLoading: PropTypes.bool.isRequired,
    carriersData: PropTypes.array,
    branchesLoading: PropTypes.bool.isRequired,
    branchesData: PropTypes.array
};

export default UpdateBookingModal;
