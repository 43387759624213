import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import history from './history';
import {application} from './application';
import {clearConfig, getConfigValue, setConfigValue} from "./config";
import {ticksToSeconds} from "./date";


export async function refreshToken() {
    let apiResponse = {};

    const headers = {
        'Authorization': `${getConfigValue('auth.jwt')}`
    };

    const refresh_token = getConfigValue('auth.refresh_token');
    const user_id = getConfigValue('auth.user.id');

    if (!refresh_token) {
        clearConfig();
        history.push('/login');
        return false;
    }

    try {
        const response = await axios.request({
            method: 'post',
            url: `${application.api_base_url}?MessageType=RefreshLogin`,
            headers,
            data: {
                id: user_id,
                refreshtoken: refresh_token
            }
        });

        apiResponse = {
            success: true,
            payload: response.data
        };

        setConfigValue('auth.jwt', response.data.token);
        setConfigValue('auth.jwt_expiry', ticksToSeconds(response.data.tokenexpiry));
        setConfigValue('auth.refresh_token', response.data.refreshtoken);

    } catch(error) {
        clearConfig();
        history.push('/login');
        apiResponse = {
            success: false,
            error: {
                message: 'Not authorised'
            }
        };
    }
    return apiResponse;
}

export function APICancelRequest() {
    const cancelSource = axios.CancelToken.source();
    return [cancelSource.token, cancelSource.cancel];
}

export function APIError(error) {
    const default_message = application.default_error;
    return _.isObject(error) ? _.get(error, 'data.Message', default_message) : default_message;
}

export async function APICall({url, method, authorised, params, data, cancel_token}) {

    if (!_.isString(url)) throw new Error('url passed to APICall must be a string');

    method = _.defaultTo(method, 'get');
    authorised = _.defaultTo(authorised, true);
    params = _.defaultTo(params, {});
    data = _.defaultTo(data, {});
    cancel_token = _.defaultTo(cancel_token, null);

    const jwt  = getConfigValue('auth.jwt');
    const jwt_expiry = getConfigValue('auth.jwt_expiry');

    if (!jwt && authorised) {
        clearConfig();
        history.push('/login');
        return;
    }

    function runRequest() {
        const headers = {};
        headers['Content-Type'] = 'application/json';
        headers['Cache-Control'] = 'no-cache';
        headers['Pragma'] = 'no-cache';
        if (authorised) headers['Authorization'] = getConfigValue('auth.jwt');

        return axios.request({
            method,
            url: `${application.api_base_url}${url}`,
            params,
            data,
            headers,
            cancelToken: cancel_token
        });
    }

    let require_refresh = false;

    if (url.indexOf('login') === -1 && url.indexOf('logout') === -1) {
        if (authorised && Number(jwt_expiry) <= moment().unix()) {
            require_refresh = true;
        }
    }

    if (require_refresh) {
        return refreshToken()
            .then(() => {
                return runRequest();
            })
            .catch(e => {
                clearConfig();
                history.push('/login');
            });
    } else {
        return runRequest();
    }

}
