import React from "react";
import PropTypes from "prop-types";
import {Badge} from 'react-bootstrap';

import './styles.scss';
import {application} from "../../../util/application";

const BookingStatusBadge = ({bookingstatus, noshow, cancelled}) => {

    let variant, label;

    const statusDetails = application.bookings_status_types.find(status => status.statusCode === bookingstatus);

    if (statusDetails) {
        variant = statusDetails.variant;
        label = statusDetails.label;
    }

    if (noshow) {
        variant = 'container-noshow';
        label = 'No show';
    }

    if (cancelled) {
        variant = 'container-cancelled';
        label = 'Cancelled';
    }

    return <Badge variant={variant}>{label}</Badge>;
};


BookingStatusBadge.propTypes = {
    bookingstatus: PropTypes.string,
    noshow: PropTypes.bool,
    cancelled: PropTypes.bool
};

BookingStatusBadge.defaultProps = {
    bookingstatus: 'PND',
    noshow: false,
    cancelled: false
};

export default BookingStatusBadge;
