import React, {
    useEffect,
    useState
} from "react";
import {Link} from 'react-router-dom';
import "./styles.scss";
import PageTitle from "../../components/layout/PageTitle";

import {
    TableCollapsible,
    TableCollapsibleRow
} from "../../components/tables/TableCollapsible";
import Loader from "../../components/layout/Loader";
import {
    faCheck
} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    APICall,
    APICancelRequest,
    APIError
} from "../../util/api";
import axios from "axios";
import {
    Alert,
    Button, Dropdown
} from "react-bootstrap";
import {
    getBranchName,
    getCarrierName,
    useBranchesData,
    useCarriersData
} from "../../util/data-hooks";
import {application} from "../../util/application";
import {useAuth} from "../../util/auth";
import ConfirmModal from "../../components/modals/ConfirmModal";
import ChangePasswordModal from "../../components/modals/ChangePasswordModal";

const UsersPage = () => {

    const [usersLoading, setUsersLoading] = useState(true);
    const [usersError, setUsersError] = useState(null);
    const [usersData, setUsersData] = useState(null);
    const {user} = useAuth();

    const [refreshUsers, setRefreshUsers] = useState(false);

    const [branchesLoading, branchesData, branchesError] = useBranchesData();
    const [carriersLoading, carriersData, carriersError] = useCarriersData();

    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const [changePasswordModalShow, setChangePasswordModalShow] = useState(false);
    const [changePasswordUserLogin, setChangePasswordUserLogin] = useState();

    const [successMessage, setSuccessMessage] = useState(null);

    const changeUserPassword = (userlogin) => {
        setChangePasswordUserLogin(userlogin);
        setChangePasswordModalShow(true);
    };

    const confirmDeleteUser = (delete_id) => {

        setDeleteLoading(true);
        setDeleteError(null);

        APICall({
            method: 'post',
            url: `?MessageType=DeleteUser`,
            data: {
                id: user.id,
                userid: delete_id
            }
        })
            .then(response => {
                setSuccessMessage('User deleted successfully');
                setRefreshUsers(Date.now());
                setDeleteLoading(false);
                setDeleteModalShow(false);
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    setDeleteError(APIError(err.response));
                    setDeleteLoading(false);
                }
            });
    };

    useEffect(() => {

        const [cancel_token, cancelRequest] = APICancelRequest();
        setUsersLoading(true);

        const user_deleted = localStorage.getItem('user_deleted');
        const user_created = localStorage.getItem('user_created');

        if (!!user_deleted) {
            localStorage.removeItem('user_deleted');
            setSuccessMessage(user_deleted);
        }

        if (!!user_created) {
            localStorage.removeItem('user_created');
            setSuccessMessage(user_created);
        }

        APICall({
            method: 'post',
            url: '?MessageType=GetUsers',
            data: {id: user.id},
            cancel_token
        })
            .then(response => {
                setUsersData(response.data);
                setUsersLoading(false);
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    setUsersError(APIError(err.response));
                    setUsersLoading(false);
                    setUsersData(null);
                }
            });

        return () => {
            cancelRequest('Cancelled request');
        };

    }, [user.id, refreshUsers]);


    return (
        <>
            <PageTitle title="Users">
                <Button as={Link} to="/users/create" variant="primary">Create User</Button>
            </PageTitle>
            <div className="container-fluid">
                {
                    !!successMessage &&
                        <Alert variant="success">{successMessage}</Alert>
                }
                    {
                            usersLoading ?
                                <Loader />
                                :
                                usersError || branchesError || carriersError ?
                                    <Alert variant="danger">{application.default_error}</Alert>
                                    :
                                    usersData.users.length ?
                                        <TableCollapsible striped hover>
                                            <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>ID</th>
                                                <th>Branch</th>
                                                <th>Carrier</th>
                                                <th>Administrator</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                usersData.users.map((user, i) => (
                                                    <TableCollapsibleRow key={`booking-${i}`}>
                                                        <td data-colname="Name" data-colprimary>{user.name}</td>
                                                        <td data-colname="ID">{user.userid}</td>
                                                        <td data-colname="Branch">
                                                            {
                                                                user.branchcode === '' ?
                                                                    ''
                                                                    :
                                                                    !branchesLoading && !!branchesData && getBranchName(branchesData, user.branchcode)
                                                            }
                                                        </td>
                                                        <td data-colname="Carrier">
                                                            {
                                                                user.carriercode === '' ?
                                                                    ''
                                                                    :
                                                                    !carriersLoading && !!carriersData && getCarrierName(carriersData, user.carriercode)
                                                            }
                                                        </td>
                                                        <td data-colname="Administrator">{user.administrator && <FontAwesomeIcon icon={faCheck} />}</td>
                                                        <td data-colname="Actions" className="table-actions">
                                                            <Dropdown alignRight>
                                                                <Dropdown.Toggle size="sm" variant={'link'}>
                                                                    Actions
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item as={Link} to={`/users/edit/${user.login}`}>Edit</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => changeUserPassword(user.login)}>Change Password</Dropdown.Item>
                                                                    <Dropdown.Item className="text-danger" onClick={() => setDeleteModalShow(user.userid)}>Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </TableCollapsibleRow>
                                                ))
                                            }
                                            </tbody>
                                        </TableCollapsible>
                                        :
                                        <Alert variant="warning">There are no users available.</Alert>
                    }
            </div>

            <ConfirmModal
                show={!!deleteModalShow}
                closeModal={() => {setDeleteError(null); setDeleteModalShow(false);}}
                onConfirm={() => confirmDeleteUser(deleteModalShow)}
                confirmText="Delete"
                confirmVariant="danger"
                confirmLoading={deleteLoading}
                alert={!!deleteError ? <Alert variant="danger">{deleteError}</Alert> : false}
                message="Are you sure you want to delete this user? This action cannot be undone." />

            <ChangePasswordModal
                show={changePasswordModalShow}
                closeModal={() => {setChangePasswordUserLogin(undefined); setChangePasswordModalShow(false);}}
                userlogin={changePasswordUserLogin}
                setSuccessMessage={setSuccessMessage} />
        </>
    );
};

export default UsersPage;
