import React from "react";
import PropTypes from "prop-types";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis} from "recharts";

import './styles.scss';

const BookingsBarChart = ({data, branchcode}) => {
    return (
        <ResponsiveContainer width="100%" height={300} className="bookings-bar-chart">
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{top: 30, right: 0, left: 0, bottom: 0}}
            >
                <CartesianGrid stroke="#eeeeee" vertical={false} />
                <XAxis interval={0} dataKey="axis_name" tick={{fill: '#6c757d', fontSize: '12px'}} />
                <Tooltip
                    cursor={{fill: '#f8f9fa'}}
                    content={
                        <BookingsBarChartToolTip />
                    }
                />
                <Bar name="Available (IN)" dataKey="in_available" stackId="a" fill="#28a745" />
                <Bar name="Booked (IN)" dataKey="in_booked" label={<BookingsBarChartLabel branchcode={branchcode} direction="IN" />} stackId="a" fill="#dee2e6" />
                <Bar name="Available (OUT)" dataKey="out_available" stackId="b" fill="#28a745" />
                <Bar name="Booked (OUT)" dataKey="out_booked" label={<BookingsBarChartLabel branchcode={branchcode} direction="OUT" />} stackId="b" fill="#dee2e6" />
            </BarChart>
        </ResponsiveContainer>
    );
};

BookingsBarChart.propTypes = {
    data: PropTypes.array.isRequired,
    branchcode: PropTypes.string.isRequired
};

const BookingsBarChartLabel = (props) => {
    const {x, y, direction, branchcode} = props;
    return (
        <text
            x={x + 5}
            y={branchcode === 'PC' ? y + 10.5 : y + 14.5}
            transform={`rotate(-90, ${x} ${y})`}
            textAnchor="start"
            className="bookings-bar-chart-label">{direction}</text>
    );
};

BookingsBarChartLabel.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    direction: PropTypes.string.isRequired,
    branchcode: PropTypes.string.isRequired
};

const BookingsBarChartToolTip = ({payload}) => {

    if (!payload || !payload.length) return null;

    const in_data = payload.filter(item => item.dataKey === 'in_available' || item.dataKey === 'in_booked');
    const out_data = payload.filter(item => item.dataKey === 'out_available' || item.dataKey === 'out_booked');

    return (
        <div className="bookings-bar-chart-tooltip">
            {
                !!payload && payload.length ?
                    <>
                        <h5>{payload[0].payload.name}</h5>
                        <div className="bookings-bar-chart-tooltip-in">
                            <div className="bookings-bar-chart-tooltip-title">IN</div>
                            {
                                !!in_data.length ?
                                    in_data.map((item, i) => {
                                        if (item.dataKey === 'in_available') return <div key={`tooltip-item-${i}`} className="bookings-bar-chart-tooltip-available">Available: <strong>{item.value}</strong></div>;
                                        if (item.dataKey === 'in_booked') return <div key={`tooltip-item-${i}`} className="bookings-bar-chart-tooltip-booked">Booked: <strong>{item.value}</strong></div>;
                                        return null;
                                    })
                                    :
                                    <div className="bookings-bar-chart-tooltip-unavailable">Unavailable</div>
                            }
                        </div>
                        <div className="bookings-bar-chart-tooltip-out">
                            <div className="bookings-bar-chart-tooltip-title">OUT</div>
                            {
                                !!out_data.length ?
                                    out_data.map((item, i) => {
                                        if (item.dataKey === 'out_available') return <div key={`tooltip-item-out-${i}`} className="bookings-bar-chart-tooltip-available">Available: <strong>{item.value}</strong></div>;
                                        if (item.dataKey === 'out_booked') return <div key={`tooltip-item-out-${i}`} className="bookings-bar-chart-tooltip-booked">Booked: <strong>{item.value}</strong></div>;
                                        return null;
                                    })
                                    :
                                    <div className="bookings-bar-chart-tooltip-unavailable">Unavailable</div>
                            }
                        </div>
                    </>
                    :
                    ''
            }
        </div>
    );
};

export default BookingsBarChart;
