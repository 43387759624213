import React, {useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import _ from "lodash";
import PageTitle from "../../components/layout/PageTitle";
import {Alert, Button, Dropdown, Form, Pagination} from "react-bootstrap";
import {APICall, APIError} from "../../util/api";
import DatePicker from "react-datepicker";
import Loader from "../../components/layout/Loader";
import {dateToTicks, urlDateRegex} from "../../util/date";
import axios from "axios";
import {getQueryParams} from "../../util/query-params";
import {Link, useLocation} from "react-router-dom";
import history from "../../util/history";
import {useBranchesData, useCarriersData} from "../../util/data-hooks";
import {application} from "../../util/application";
import UpdateBookingModal from "./UpdateBookingModal";
import BookingsTable from "./BookingsTable";
import ConfirmModal from "../../components/modals/ConfirmModal";
import {useAuth} from "../../util/auth";
import {useInterval} from "react-use";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight, faThList} from "@fortawesome/pro-regular-svg-icons";
import BookingsTableColumnsModal from "./BookingsTableColumnsModal";
import FormFilter from "../../components/forms/FormFilter";
import {runScrollToTop} from "../../util/scroll-to-top";

import "./styles.scss";

const allowedStatus = ["PND", "ACT", "COM", "NSH", "LAT", "ERL", "EXP"];

const ViewBookingsPage = () => {

    const {user, userHasPermission} = useAuth(),
        location = useLocation(),
        [currentSlotDate, setCurrentSlotDate] = useState(moment().format("ddd Do MMM YYYY ha")),
        [bookingsLoading, setBookingsLoading] = useState(true),
        [bookingsError, setBookingsError] = useState(null),
        [bookingsData, setBookingsData] = useState(null),
        [bookingToUpdate, setBookingToUpdate] = useState(null),
        [showUpdateModal, setShowUpdateModal] = useState(false),
        [branchesLoading, branchesData, branchesError] = useBranchesData(true),
        [carriersLoading, carriersData, carriersError] = useCarriersData(),
        [showCancelModal, setShowCancelModal] = useState(false),
        [cancelBookingLoading, setCancelBookingLoading] = useState(false),
        [cancelBookingError, setCancelBookingError] = useState(null),
        [bookingsColumns, setBookingsColumns] = useState(null),
        [showColumnsModal, setShowColumnsModal] = useState(false),
        [statusCheckboxes, setStatusCheckboxes] = useState({}),
        [filtersOpen, setFiltersOpen] = useState(false);

    const userHasCreateBooking = useMemo(() => userHasPermission("createbooking"), [userHasPermission]);

    const {date_from, date_to, status, depot, carrier, show_cancelled, page} = useMemo(() => {
        const params = getQueryParams(location.search, ["date_from", "date_to", "status", "depot", "carrier", "show_cancelled", "page"]);
        const sanitised = {
            date_from: params.date_from,
            date_to: params.date_to,
            status: params.status,
            depot: params.depot,
            carrier: params.carrier,
            show_cancelled: params.show_cancelled,
            page: parseInt(params.page, 10)
        };

        if (!urlDateRegex(sanitised.date_from)) sanitised.date_from = moment().format("DD-MM-YYYY");
        if (!urlDateRegex(sanitised.date_to)) sanitised.date_to = moment().add(5, "days").format("DD-MM-YYYY");
        // if (!["PND", "ACT", "COM", "NSH", "LAT", "ERL", "EXP"].find(v => v === sanitised.status)) sanitised.status = "";

        if (sanitised.status && sanitised.status.length) {
            const statusArray = sanitised.status.split('|').filter(s => allowedStatus.find(a => a === s));
            sanitised.status = statusArray.length ? statusArray.join('|') : allowedStatus.join('|');
        } else {
            sanitised.status = "";
        }

        if (!["SF", "PC"].find(v => v === sanitised.depot)) sanitised.depot = "";
        if (!sanitised.carrier) sanitised.carrier = "";
        sanitised.show_cancelled = sanitised.show_cancelled === "true";
        if (!_.isInteger(sanitised.page) || sanitised.page < 1) sanitised.page = 1;

        return sanitised;
    }, [location.search]);

    const updateURL = useCallback((updated) => {
        const params = {
            date_from,
            date_to,
            status,
            depot,
            carrier,
            show_cancelled,
            ...updated
        };
        const qs = new URLSearchParams(params).toString();
        return `${location.pathname}?${qs}`;
    }, [location.pathname, date_from, date_to, status, depot, carrier, show_cancelled]);

    // const currentStatus = useMemo(() => {
    //     const findStatus = application.bookings_status_types.find(bookingStatus => status === bookingStatus.statusCode);
    //     console.log('findStatus', findStatus);
    //     return findStatus || null;
    // }, [status]);

    const handlePageChange = useCallback((page) => {
        const push_url = updateURL({page});
        history.push(push_url);
        runScrollToTop();
    }, [updateURL]);

    const statusArray = useMemo(() => {
        return status.length ? status.split('|') : [];
    }, [status]);

    const handleStatusChange = useCallback((e) => {
        const name = e.target.name;
        const checked = e.target.checked;

        if (name === 'show-all') {
            history.push(updateURL({status: checked ? '' : allowedStatus.join('|')}));
            return;
        }

        const newStatusArray = [...statusArray];
        const index = newStatusArray.findIndex(s => s === name);
        if (checked && index === -1) newStatusArray.push(name);
        if (!checked && index > -1) newStatusArray.splice(index, 1);
        const newStatus = newStatusArray.join('|').replace(/^\||\|$/g, '');
        const push_url = updateURL({status: newStatus});
        history.push(push_url);
    }, [statusArray, updateURL]);

    const handleShowCancelledChange = useCallback((e) => {
        const push_url = updateURL({show_cancelled: e.target.checked});
        history.push(push_url);
    }, [updateURL]);

    const handleDepotChange = useCallback((e) => {
        const push_url = updateURL({depot: e.target.value});
        history.push(push_url);
    }, [updateURL]);

    const handleCarrierChange = useCallback((e) => {
        const push_url = updateURL({carrier: e.target.value});
        history.push(push_url);
    }, [updateURL]);

    const handleDateChange = useCallback((date_obj) => {
        let new_date_from = moment(_.get(date_obj, "date_from", moment(date_from, "DD-MM-YYYY").toDate())),
            new_date_to = moment(_.get(date_obj, "date_to", moment(date_to, "DD-MM-YYYY").toDate()));

        if (new_date_to.diff(new_date_from, "days") <= 0) new_date_to = new_date_from;

        const push_url = updateURL({
            date_from: new_date_from.format("DD-MM-YYYY"),
            date_to: new_date_to.format("DD-MM-YYYY")
        });

        history.push(push_url);
    }, [date_from, date_to, updateURL]);

    const bookingsFilteredData = useMemo(() => {
        if (!bookingsData) return null;

        const bookings = bookingsData
            .filter(booking => !!user.userbranches.find(userbranch => userbranch.branchcode === booking.branchcode))
            .filter(booking => !show_cancelled ? booking.cancelled === false : true)
            .filter(booking => {

                if (statusArray.length) {
                    // No shows not selected
                    if (!statusArray.find(s => s === 'NSH')) {
                        console.log('No shows not selected');
                        return statusArray.find(s => booking.bookingstatus === s) && booking.noshow === false;
                    }

                    // No shows are selected
                    console.log('No shows irrelevant');
                    return statusArray.find(s => booking.bookingstatus === s) || booking.noshow === true;
                }

                // if (status === "NSH") {
                //     return booking.bookingstatus === "NSH" || booking.noshow === true;
                // }
                //
                // if (status !== "") {
                //     return booking.bookingstatus === status && booking.noshow === false;
                // }

                return true;
            })
            .filter(booking => depot !== "" ? booking.branchcode === depot : true)
            .filter(booking => carrier !== "" ? booking.carriercode === carrier : true)
            .sort((a, b) => {
                const compare = b.slotdateticks - a.slotdateticks;
                return compare === 0 ? b.slothour - a.slothour : compare;
            });

        const page_size = 50;
        const meta = {
            page,
            page_size,
            current_results: `
                ${(page * page_size) - page_size + 1} - ${(page * page_size) > bookings.length ? bookings.length : page * page_size}
                of ${bookings.length}
            `,
            total_results: bookings.length,
            total_pages: Math.ceil(bookings.length / page_size)
        };

        meta.prev_page = meta.page > 1 ? meta.page - 1 : false;
        meta.next_page = meta.page < meta.total_pages ? meta.page + 1 : false;

        return {
            bookings: bookings.slice((meta.page * meta.page_size) - meta.page_size, meta.page * meta.page_size),
            meta
        };

    }, [statusArray, carrier, depot, show_cancelled, page, bookingsData, user.userbranches]);

    const handleUpdateModal = useCallback((booking) => {
        setBookingToUpdate(booking);
        setShowUpdateModal(true);
    }, [setBookingToUpdate, setShowUpdateModal]);

    const handleCancelModal = useCallback((booking) => {
        setBookingToUpdate(booking);
        setShowCancelModal(true);
    }, [setBookingToUpdate, setShowCancelModal]);

    const getBookings = useCallback((showLoading) => {
        if (showLoading) setBookingsLoading(true);

        const allColumns = {};
        const userColumns = JSON.parse(_.defaultTo(localStorage.getItem("bookings_columns"), "{}"));

        Object.keys(application.bookings_columns).forEach(key => {
            allColumns[key] = application.bookings_columns[key].value;
        });

        setBookingsColumns({
            ...allColumns,
            ...userColumns
        });

        const fromDateTicks = dateToTicks(moment(date_from, "DD-MM-YYYY").startOf("day").toDate()),
            toDateTicks = dateToTicks(moment(date_to, "DD-MM-YYYY").endOf("day").toDate());

        APICall({
            method: "post",
            url: "?MessageType=GetBookings",
            data: {id: user.id, fromticks: fromDateTicks, toticks: toDateTicks}
        })
            .then(response => {
                setBookingsError(null);
                setBookingsData(_.get(response, "data.bookings", []));
                setBookingsLoading(false);
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    setBookingsError(APIError(err.response));
                    setBookingsLoading(false);
                    setBookingsData(null);
                }
            });

    }, [date_from, date_to, user.id]);

    const confirmCancelBooking = useCallback((booking) => {
        setCancelBookingLoading(true);

        APICall({
            method: "post",
            url: "?MessageType=UpdateBooking",
            data: {
                id: user.id,
                cancel: true,
                bookingnumber: booking.bookingno,
                bookingdateticks: booking.slotdateticks,
                branchcode: booking.branchcode
            }
        })
            .then(() => {
                getBookings(false);
                setShowCancelModal(false);
                setCancelBookingLoading(false);
            })
            .catch(err => {
                const errors = _.get(err, 'response.data.errors', null);
                setCancelBookingError(errors);
                setCancelBookingLoading(false);
            });
    }, [user.id, getBookings]);

    const setColumns = useCallback((columns) => {
        localStorage.setItem("bookings_columns", JSON.stringify(columns));
        setBookingsColumns(columns);
        setShowColumnsModal(false);
    }, []);

    useEffect(() => {
        getBookings(true);
    }, [getBookings]);

    useEffect(() => {
        const o = {};
        allowedStatus.forEach(status => {
            o[status] = statusArray.find(s => s === status);
        });
        setStatusCheckboxes(o);
    }, [statusArray]);

    useInterval(() => {
        setCurrentSlotDate(moment().format("ddd Do MMM YYYY ha"));
        getBookings(false);
    }, application.bookings_refresh_interval);

    return (
        <>
            <PageTitle title="View Bookings">
                {userHasCreateBooking ?
                    <Button as={Link} to={`/bookings/create${depot !== "" ? "?depot=" + depot : ""}`} variant="primary">Create
                        Booking</Button> : null}
            </PageTitle>
            <div className="container-fluid">
                <div className={`form-filters ${filtersOpen ? "form-filters-open" : ""}`}>
                    <div className="form-filters-trigger" onClick={() => setFiltersOpen(!filtersOpen)}>
                        Filters <FontAwesomeIcon icon={filtersOpen ? faChevronDown : faChevronRight}/>
                    </div>
                    <div className="form-filters-container">
                        <div className="row">
                            <FormFilter label="From">
                                <DatePicker
                                    selected={moment(date_from, "DD-MM-YYYY").toDate()}
                                    onChange={date => handleDateChange({date_from: date})}
                                    dateFormat="dd MMM yyyy"
                                    className="form-control form-control-sm"
                                />
                            </FormFilter>
                            <FormFilter label="To">
                                <DatePicker
                                    selected={moment(date_to, "DD-MM-YYYY").toDate()}
                                    onChange={date => handleDateChange({date_to: date})}
                                    dateFormat="dd MMM yyyy"
                                    className="form-control form-control-sm"
                                />
                            </FormFilter>
                            {
                                !branchesLoading &&
                                <FormFilter label="Depot">
                                    <Form.Control
                                        name="branchcode"
                                        value={depot}
                                        onChange={handleDepotChange}
                                        as="select"
                                        size="sm">
                                        <option value="">All depots</option>
                                        {
                                            branchesData.map((branch, i) =>
                                                <option key={`branch-${i}`} value={branch.branchcode}>{branch.name}</option>)
                                        }
                                    </Form.Control>
                                </FormFilter>
                            }
                            {
                                user.administrator && !carriersLoading &&
                                <FormFilter label="Carrier">
                                    <Form.Control
                                        name="carrier"
                                        value={carrier}
                                        onChange={handleCarrierChange}
                                        as="select"
                                        size="sm">
                                        <option value="">All carriers</option>
                                        {
                                            carriersData.map((carrier, i) => <option key={`carrier-${i}`}
                                                                                     value={carrier.carriercode}>{carrier.name}</option>)
                                        }
                                    </Form.Control>
                                </FormFilter>
                            }
                        </div>
                        <div className="row">
                            <FormFilter label="Status">
                                <Dropdown className="booking-status-dropdown d-inline-block">
                                    <Dropdown.Toggle size="sm" variant="light">
                                        {
                                            !statusArray.length ?
                                                'Show all'
                                                :
                                                <>
                                                    {statusArray.length} selected
                                                </>
                                        }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/*<Dropdown.Item onClick={() => handleStatusChange('')}>Show all</Dropdown.Item>*/}
                                        <div className="form-filters-status-item">
                                            <Form.Check custom
                                                        type="checkbox"
                                                        name="show-all"
                                                        id="status-check-show-all"
                                                        label="Show all"
                                                        checked={!statusArray.length}
                                                        onChange={handleStatusChange} />
                                        </div>
                                        <div className={`form-filters-status-container ${!statusArray.length && 'd-none'}`}>
                                            <Dropdown.Divider />
                                            {
                                                application.bookings_status_types
                                                    .filter(status => status.statusCode !== 'CAN')
                                                    .map((status, i) => (
                                                        <div key={`status-dropdown-${i}`} className={`form-filters-status-item text-${status.variant}`}>
                                                            <Form.Check custom
                                                                        type="checkbox"
                                                                        name={status.statusCode}
                                                                        id={`status-check-${i}`}
                                                                        label={status.label}
                                                                        disabled={!statusArray.length}
                                                                        checked={!statusArray.length || statusCheckboxes[status.statusCode] || false}
                                                                        onChange={handleStatusChange} />
                                                        </div>
                                                    ))
                                            }
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </FormFilter>
                            <FormFilter>
                                <Form.Check custom type="checkbox" name="show_cancelled" id="show_cancelled"
                                            defaultChecked={show_cancelled}
                                            onChange={handleShowCancelledChange}
                                            label="Show cancelled bookings"/>
                            </FormFilter>
                            <FormFilter>
                                <Button variant="outline-primary" size="sm" onClick={() => setShowColumnsModal(true)}><FontAwesomeIcon icon={faThList}/> Columns</Button>
                            </FormFilter>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                {
                    bookingsError || branchesError || carriersError ?
                        <Alert variant="danger">{application.default_error}</Alert>
                        :
                        bookingsLoading || branchesLoading || carriersLoading ?
                            <Loader/>
                            :
                            !!bookingsFilteredData && bookingsFilteredData.bookings.length ?
                                <>
                                    <BookingsTable
                                        bookingsData={bookingsFilteredData.bookings}
                                        carriersLoading={carriersLoading}
                                        carriersData={carriersData}
                                        carriersError={carriersError}
                                        branchesLoading={branchesLoading}
                                        branchesData={branchesData}
                                        branchesError={branchesError}
                                        handleUpdateModal={handleUpdateModal}
                                        handleCancelModal={handleCancelModal}
                                        bookingsColumns={bookingsColumns}
                                        currentSlotDate={currentSlotDate}/>
                                    <div className="pagination-container">
                                        <div className="row">
                                            <div className="col-md-6">
                                                Showing {bookingsFilteredData.meta.current_results} records
                                            </div>
                                            <div className="col-md-6">
                                                <Pagination>
                                                    {/*<Pagination.First disabled={bookingsFilteredData.meta.page <= 1} onClick={() => handlePageChange(1)}>&laquo; First</Pagination.First>*/}
                                                    <Pagination.Prev disabled={!bookingsFilteredData.meta.prev_page}
                                                                     onClick={() => handlePageChange(bookingsFilteredData.meta.prev_page)}>Prev</Pagination.Prev>
                                                    <Pagination.Next disabled={!bookingsFilteredData.meta.next_page}
                                                                     onClick={() => handlePageChange(bookingsFilteredData.meta.next_page)}>Next</Pagination.Next>
                                                    {/*<Pagination.Last disabled={bookingsFilteredData.meta.page >= bookingsFilteredData.meta.total_pages} onClick={() => handlePageChange(bookingsFilteredData.meta.total_pages)} />*/}
                                                </Pagination>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <Alert variant="warning">There are no bookings available with the selected
                                    filters.</Alert>
                }
            </div>
            <UpdateBookingModal
                show={showUpdateModal}
                booking={bookingToUpdate}
                handleClose={() => setShowUpdateModal(false)}
                setRefreshBookings={() => getBookings(false)}
                carriersLoading={carriersLoading}
                carriersData={carriersData}
                branchesLoading={branchesLoading}
                branchesData={branchesData}/>
            <ConfirmModal
                show={showCancelModal}
                closeModal={() => setShowCancelModal(false)}
                onConfirm={() => confirmCancelBooking(bookingToUpdate)}
                confirmLoading={cancelBookingLoading}
                cancelText={"Close"}
                confirmText="Cancel Booking"
                confirmVariant="danger"
                alert={
                    !!cancelBookingError
                        ?
                        <Alert variant="danger">{
                            _.isArray(cancelBookingError) ?
                                <>
                                    {
                                        cancelBookingError.map((err, i) => (
                                            <p key={`cancel-booking-error-${i}`}>{err.error}</p>
                                        ))
                                    }
                                </>
                                :
                                <p>Error: Could not cancel booking</p>
                        }</Alert>
                        :
                        false
                }
                message="Are you sure you want to cancel this booking?"/>
            <BookingsTableColumnsModal
                show={showColumnsModal}
                setColumns={setColumns}
                handleClose={() => setShowColumnsModal(false)}
                bookingsColumns={bookingsColumns}/>
        </>
    );
};

export default ViewBookingsPage;
