import React, {useState} from "react";
import "./styles.scss";
import PageTitle from "../../components/layout/PageTitle";
import {Alert, Form, FormControl, Table} from "react-bootstrap";
import Loader from "../../components/layout/Loader";
import {getBranchName, useBranchesData} from "../../util/data-hooks";
import _ from "lodash";
import {useAuth} from "../../util/auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";
import {useForm} from "react-hook-form";
import {APICall} from "../../util/api";
import axios from "axios";
import FormButton from "../../components/forms/FormButton";
import moment from "moment";
import {ticksToDate} from "../../util/date";


const ReleaseEnquiryPage = () => {
    const {user} = useAuth();
    const {register, handleSubmit, errors, setError} = useForm();
    const [branchesLoading, branchesData, branchesError] = useBranchesData(true);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [enquiryData, setEnquiryData] = useState(null);

    const onSubmit = data => {
        setFormSubmitting(true);
        setEnquiryData(null);

        APICall({
            method: "post",
            url: "?MessageType=ReleaseEnquiry",
            data: {...data, id: user.id}
        })
            .then(response => {
                setEnquiryData(_.get(response, "data.releases", []).map(release => ({
                    ...release,
                    dateticks: release.dateticks > 0 ? moment(ticksToDate(release.dateticks)).format("DD/MM/YYYY") : "",
                    effectivedateticks: release.effectivedateticks > 0 ? moment(ticksToDate(release.effectivedateticks)).format("DD/MM/YYYY") : "",
                    expirydateticks: release.expirydateticks > 0 ? moment(ticksToDate(release.expirydateticks)).format("DD/MM/YYYY") : "",
                    completed: release.completed ? "Yes" : "No"
                })));
                setFormSubmitting(false);
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    const errors = _.get(err, "response.data.errors", []);

                    if (!_.isEmpty(errors)) {
                        errors.forEach(error => {
                            setError(error.field, "error", error.error);
                        });
                    }

                    setFormSubmitting(false);
                }
            });
    };

    return (
        <>
            <PageTitle title="Release Enquiry"/>
            <div className="container-fluid">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4">
                            {
                                branchesLoading ?
                                    <Loader/>
                                    :
                                    branchesError ?
                                        <Alert variant="danger">{branchesError}</Alert>
                                        :
                                        <Form.Group controlId="branchcode">
                                            <Form.Label>Depot</Form.Label>
                                            <FormControl
                                                ref={register({required: "You must select a depot"})}
                                                as="select"
                                                name="branchcode"
                                                isInvalid={!!errors.branchcode}
                                                defaultValue={""}>
                                                <option value="">Select...</option>
                                                {
                                                    branchesData.map((branch, i) => (
                                                        <option key={`branch-${i}`}
                                                                value={branch.branchcode}>
                                                            {branch.name}
                                                        </option>
                                                    ))
                                                }
                                            </FormControl>
                                            {
                                                errors.branchcode &&
                                                <Form.Control.Feedback type="invalid">
                                                    <FontAwesomeIcon icon={faExclamationTriangle}/> {errors.branchcode.message}
                                                </Form.Control.Feedback>
                                            }
                                        </Form.Group>
                            }
                        </div>

                        <div className="col-12 col-sm-6 col-md-4">
                            <Form.Group controlId="releasenumber">
                                <Form.Label>Release number:</Form.Label>
                                <Form.Control
                                    name="releasenumber"
                                    ref={register({required: "You must enter a release number"})}
                                    isInvalid={!!errors.releasenumber}
                                    defaultValue={""}/>
                                {
                                    errors.releasenumber &&
                                    <Form.Control.Feedback type="invalid">
                                        <FontAwesomeIcon icon={faExclamationTriangle}/> {errors.releasenumber.message}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </div>

                        <div className="col-12">
                            <Form.Group controlId="submit">
                                <FormButton type="submit" variant="primary"
                                            loading={formSubmitting}>Submit</FormButton>
                            </Form.Group>
                        </div>

                    </div>
                </form>

                {
                    !!enquiryData &&
                    <div className="row">
                        <div className="col-12">
                            {
                                !enquiryData.length ?
                                    <Alert variant="warning">No information is available for this release number</Alert>
                                    :
                                    <>
                                        <Table striped responsive>
                                            <thead>
                                            <tr>
                                                <th>Release number</th>
                                                <th>Depot</th>
                                                <th>Customer</th>
                                                <th>Date Booked</th>
                                                <th>Effective Date</th>
                                                <th>Expiry Date</th>
                                                <th>Deliver To</th>
                                                <th>Release To</th>
                                                <th>Vessel</th>
                                                <th>Voyage</th>
                                                <th>Lift Out To</th>
                                                <th>Qty Booked</th>
                                                <th>Qty Released</th>
                                                <th>Balance</th>
                                                <th>Completed</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                enquiryData.map(release => (
                                                    <tr>
                                                        <td>{release.releasenumber}</td>
                                                        <td>{getBranchName(branchesData, release.branchcode)}</td>
                                                        <td>{release.customer}</td>
                                                        <td>{release.dateticks}</td>
                                                        <td>{release.effectivedateticks}</td>
                                                        <td>{release.expirydateticks}</td>
                                                        <td>{release.deliverto}</td>
                                                        <td>{release.releaseto}</td>
                                                        <td>{release.vessel}</td>
                                                        <td>{release.voyage}</td>
                                                        <td>{release.liftoutchargeto}</td>
                                                        <td>{release.qtybooked}</td>
                                                        <td>{release.qtyreleased}</td>
                                                        <td>{release.balance}</td>
                                                        <td>{release.completed}</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                    </>
                            }
                        </div>
                    </div>
                }

            </div>
        </>
    );
};

export default ReleaseEnquiryPage;
