import React, {useState} from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import FormButton from "../../../components/forms/FormButton";
import {
    Button, Form,
    Modal
} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {application} from "../../../util/application";

const BookingsTableColumnsModal = ({show, setColumns, bookingsColumns, handleClose}) => {

    const [columnsSaving, setColumnsSaving] = useState(false),
          {register, handleSubmit} = useForm();

    const onSubmit = (data) => {
        setColumnsSaving(true);
        setColumns(data);
        setColumnsSaving(false);
        handleClose();
    };

    if (!bookingsColumns) return null;

    return (
        <Modal backdrop="static" show={show} onHide={handleClose} className="update-booking-modal">
            <Modal.Header>
                <Modal.Title as="h2">Select Columns</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div className="row">
                        {
                            Object.keys(application.bookings_columns).map((key, i) => (
                                <div key={`bookings-column-${i}`}
                                     className="col-sm-6 col-md-12 col-lg-6">
                                    <Form.Group
                                        controlId={`bookings-column-${key}`}>
                                        <Form.Check custom type="checkbox" ref={register}
                                                    name={key}
                                                    defaultChecked={application.bookings_columns[key].can_edit ? bookingsColumns[key] : true}
                                                    disabled={!application.bookings_columns[key].can_edit}
                                                    label={application.bookings_columns[key].label} />
                                    </Form.Group>
                                </div>
                            ))
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                            <>
                                <Button variant="light" disabled={columnsSaving} onClick={handleClose}>Close</Button>
                                <FormButton type="submit" loading={columnsSaving} variant="primary">Save</FormButton>
                            </>
                    }
                </Modal.Footer>
            </form>
        </Modal>
    );
};

BookingsTableColumnsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    bookingsColumns: PropTypes.object,
    setColumns: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
};

BookingsTableColumnsModal.defaultProps = {
    show: false
};

export default BookingsTableColumnsModal;
