import React from "react";
import {Form} from "react-bootstrap";
import {useCreateBooking} from "../../../util/bookings";
import CreateBookingSection from "../CreateBookingSection";

import "./styles.scss";

const SelectContainerPositioning = () => {

    const {bookingState, setPinsPosition, setDoorPosition} = useCreateBooking();

    if (!bookingState.direction || bookingState.direction === 'IN') return null;

    return (
        <CreateBookingSection title="Container Positioning">
            <div className="row">
                <div className="col-6 col-sm-4">
                    <label>Door position:</label>
                    <Form.Control
                        name="doorposition"
                        value={bookingState.doorposition}
                        isInvalid={!!bookingState.errors.doorposition}
                        onChange={(event) => setDoorPosition(event.target.value)}
                        as="select">
                        <option value="">Select...</option>
                        <option value="DR">DR</option>
                        <option value="DF">DF</option>
                    </Form.Control>
                </div>
                <div className="col-6 col-sm-4 d-none">
                    <label>Pin position:</label>
                    <Form.Control
                        name="pinsposition"
                        value={bookingState.pinsposition}
                        isInvalid={!!bookingState.errors.pinsposition}
                        onChange={(event) => setPinsPosition(event.target.value)}
                        as="select">
                        <option value="">Select...</option>
                        <option value="FP">FP</option>
                        <option value="MP">MP</option>
                        <option value="BP">BP</option>
                    </Form.Control>
                </div>
            </div>
        </CreateBookingSection>
    );
};

export default SelectContainerPositioning;
