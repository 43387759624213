import moment from 'moment';

const epoch_ticks = 621355968000000000;

export const unixOffset = () => moment().utcOffset();

export const ticksToSeconds = (ticks) => Math.floor((parseInt(ticks, 10) - epoch_ticks) / 10000000);

export const dateToTicks = (date) => {
    const moment_date = moment(date);
    return (moment_date.add(moment_date.utcOffset(), 'minutes').unix() * 10000000) + epoch_ticks;
};

export const ticksToDate = (ticks) => moment.unix(ticksToSeconds(ticks)).add(unixOffset(), 'minutes').toDate();

export const urlDateRegex = (date) => {
    const regex = new RegExp('^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\\d\\d$');
    return regex.test(date);
};
