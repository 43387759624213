import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const CreateBookingSection = ({title, children}) => {

    return (
        <div className="section">
            <h2>{title}</h2>
            {children}
        </div>
    );
};

CreateBookingSection.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default CreateBookingSection;
