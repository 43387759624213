import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import PageTitle from "../../components/layout/PageTitle";

import {
    APICall,
    APICancelRequest,
    APIError
} from "../../util/api";
import _ from "lodash";
import {
    Link,
    useParams,
    useRouteMatch
} from 'react-router-dom';
import axios from "axios";
import {useForm} from "react-hook-form";
import {
    Alert,
    Button,
    Form,
    FormControl
} from "react-bootstrap";
import FormButton from "../../components/forms/FormButton";
import * as yup from 'yup';

import "./styles.scss";
import {
    useBranchesData,
    useCarriersData
} from "../../util/data-hooks";
import Loader from "../../components/layout/Loader";
import ConfirmModal from "../../components/modals/ConfirmModal";
import {application} from '../../util/application';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";
import history from "../../util/history";
import {useAuth} from "../../util/auth";
import {ScrollToTop} from "../../util/scroll-to-top";



const initialUserState = {
    userid: '',
    name: '',
    login: '',
    branchcode: '',
    carriercode: '',
    active: true,
    administrator: false,
    access: application.user_access,
    userbranches: []
};

const fieldLabels = {
    name: "Name",
    login: "Login",
    password: "Password",
    confirm_password: "Confirm Password",
    branchcode: "Depot",
    userbranches: "Depot Access",
    carriercode: "Carrier",
    active: "Active",
    administrator: "Administrator"
};

const EditUserPage = () => {

    const isNewUser = useRouteMatch({path: '/users/create'});
    const schema = {
        name: yup.string().required('Name is required')
    };

    if (isNewUser) {
        schema.login = yup.string().required('Login is required');
        schema.password = yup
                            .string()
                            .min(8, 'Password must contain a minimum of 8 characters')
                            .max(20, 'Password must contain a maximum of 20 characters')
                            .required('Password is required');
        schema.confirm_password = yup
                                    .string()
                                    .required('You must confirm the password')
                                    .test('passwords-match', 'Passwords do not match', function(value) {
                                        return this.parent.password === value;
                                    });
    }

    const UserSchema = yup.object().shape(schema);

    const {user} = useAuth(),
          {userlogin} = useParams(),
          {register, handleSubmit, errors, setValue, setError} = useForm({validationSchema: UserSchema}),
          [userLoading, setUserLoading] = useState(true),
          [userError, setUserError] = useState(null),
          [userData, setUserData] = useState(null),
          [formSubmitting, setFormSubmitting] = useState(false),
          [deleteModalShow, setDeleteModalShow] = useState(false),
          [formResult, setFormResult] = useState(null),
          [branchesLoading, branchesData, branchesError] = useBranchesData(),
          [carriersLoading, carriersData, carriersError] = useCarriersData();

    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const confirmDeleteUser = (delete_id) => {

        setDeleteLoading(true);

        APICall({
            method: 'post',
            url: `?MessageType=DeleteUser`,
            data: {id: user.id, userid: delete_id}
        })
            .then(response => {
                localStorage.setItem('user_deleted', 'User deleted successfully');
                history.push('/users');
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    setDeleteError(APIError(err.response));
                    setDeleteLoading(false);
                }
            });
    };

    const selectAllAccess = useCallback(() => {
        initialUserState.access.forEach(item => {
            setValue(`access.${item.name}`, true);
        });
    }, [setValue]);


    const onSubmit = data => {
        setFormSubmitting(true);

        const userbranches = [];

        Object.keys(data).forEach(item => {
            if (item.startsWith('branch-')) {
                if (data[item]) userbranches.push({branchcode: item.replace(/^branch-(.+)$/, '$1')});
            }
        });

        if (!isNewUser) data.login = userData.login;

        delete data.confirm_password;

        APICall({
            method: 'post',
            url: `?MessageType=${isNewUser ? 'CreateUser' : 'UpdateUser'}`,
            data: {...data, userbranches, id: user.id}
        })
            .then(() => {

                if (isNewUser) {
                    localStorage.setItem('user_created', 'User created successfully');
                    history.push('/users');
                } else {
                    setFormResult({
                        type: 'success',
                        message: 'User updated.'
                    });
                    setFormSubmitting(false);
                }
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    const errors = _.get(err, 'response.data.errors', []);

                    if (!_.isEmpty(errors)) {
                        errors.forEach(error => {
                            setError(error.field, 'error', error.error);
                        });
                    }

                    setFormSubmitting(false);
                }
            });
    };


    useEffect(() => {

        if (isNewUser) {
            setUserData(initialUserState);
            setUserLoading(false);
        } else {
            setUserLoading(true);

            const [cancel_token, cancelRequest] = APICancelRequest();


            if (!branchesLoading && !carriersLoading) {
                APICall({
                    method: 'post',
                    url: '?MessageType=GetUserDetails',
                    data: {id: user.id, userlogin},
                    cancel_token
                })
                    .then(response => {
                        // setUserData(response.data);

                        const user = {...initialUserState};

                        user.userid = response.data.userid;
                        user.name = response.data.name;
                        user.login = response.data.login;
                        user.active = response.data.active;
                        user.administrator = response.data.administrator;
                        user.userbranches = response.data.userbranches;

                        user.access = user.access.map(item => ({
                            ...item,
                            value: _.get(response.data, `access.${item.name}`, item.value)
                        }));

                        if (!branchesLoading && !_.isEmpty(branchesData)) {
                            const i = branchesData.findIndex(branch => branch.branchcode === response.data.branchcode);
                            if (i !== -1) user.branchcode = branchesData[i].branchcode;
                        }

                        if (!carriersLoading && !_.isEmpty(carriersData)) {
                            const i = carriersData.findIndex(carrier => carrier.carriercode === response.data.carriercode);
                            if (i !== -1) user.carriercode = carriersData[i].carriercode;
                        }

                        setUserData(user);

                        setUserLoading(false);
                    })
                    .catch(err => {
                        if (!axios.isCancel(err)) {
                            setUserError(APIError(err.response));
                            setUserLoading(false);
                            setUserData(null);
                        }
                    });
            }

            return () => cancelRequest('Cancelled request');
        }


    }, [userlogin, user.id, isNewUser, branchesData, branchesLoading, carriersData, carriersLoading]);

    let page_title = 'Create User';
    if (!userLoading && !_.isEmpty(userData)) {
        page_title = userData.name;
    }

    return (
        <>
            {
                userLoading ?
                    <PageTitle loading={true} />
                    :
                    userError ?
                        <>
                            <PageTitle title="Edit User">
                                <Button variant="light" as={Link} to="/users">Back</Button>
                            </PageTitle>
                            <div className="container-fluid">
                                <Alert variant="danger">{userError}</Alert>
                            </div>
                        </>
                        :
                        <>
                            <PageTitle title={page_title}>
                                <Link to="/users">&laquo; User Administration</Link>
                            </PageTitle>
                            <div className="container-fluid">
                                {
                                    formResult &&
                                    <Alert
                                        variant={formResult.type === 'error' ? 'danger' : 'success'}>{formResult.message}</Alert>
                                }
                                {
                                    !_.isEmpty(errors) &&
                                        <>
                                            <Alert variant="danger" className="form-errors">
                                                <p><strong>Please correct the following errors:</strong></p>
                                                <ul>
                                                    {
                                                        Object.keys(errors).map((key, i) => (
                                                            <li key={`error-${i}`}>
                                                                <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                                                                <strong>{fieldLabels[key]}:</strong>{' '}
                                                                {errors[key].message}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </Alert>
                                            <ScrollToTop />
                                        </>
                                }
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="row">
                                        <div className="col-md-6">

                                            <Form.Group controlId="user-name">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    ref={register}
                                                    name="name"
                                                    isInvalid={!!_.get(errors, 'name.message', false)}
                                                    defaultValue={userData.name}
                                                />
                                                {
                                                    errors.name &&
                                                    <Form.Control.Feedback type="invalid">
                                                        <FontAwesomeIcon icon={faExclamationTriangle} /> {errors.name.message}
                                                    </Form.Control.Feedback>
                                                }
                                            </Form.Group>

                                            <Form.Group controlId="user-login">
                                                <Form.Label>Login</Form.Label>
                                                {
                                                    isNewUser ?
                                                        <>
                                                            <Form.Control
                                                                ref={register}
                                                                name="login"
                                                                isInvalid={!!_.get(errors, 'login.message', false)}
                                                                defaultValue={userData.login}
                                                            />
                                                            {
                                                                errors.login &&
                                                                <Form.Control.Feedback type="invalid">
                                                                    <FontAwesomeIcon icon={faExclamationTriangle} /> {errors.login.message}
                                                                </Form.Control.Feedback>
                                                            }
                                                        </>
                                                        :
                                                        <div className="user-login-display">{userData.login}</div>
                                                }
                                            </Form.Group>

                                            {
                                                isNewUser &&
                                                    <>
                                                        <Form.Group controlId="user-password">
                                                            <Form.Label>Password</Form.Label>
                                                            <Form.Control
                                                                ref={register}
                                                                name="password"
                                                                type="password"
                                                                isInvalid={!!_.get(errors, 'password.message', false)}
                                                                defaultValue=""
                                                            />
                                                            {
                                                                errors.password &&
                                                                <Form.Control.Feedback type="invalid">
                                                                    <FontAwesomeIcon icon={faExclamationTriangle} /> {errors.password.message}
                                                                </Form.Control.Feedback>
                                                            }
                                                        </Form.Group>
                                                        <Form.Group controlId="user-confirm-password">
                                                            <Form.Label>Confirm Password</Form.Label>
                                                            <Form.Control
                                                                ref={register}
                                                                name="confirm_password"
                                                                type="password"
                                                                isInvalid={!!_.get(errors, 'confirm_password.message', false)}
                                                                defaultValue=""
                                                            />
                                                            {
                                                                errors.confirm_password &&
                                                                <Form.Control.Feedback type="invalid">
                                                                    <FontAwesomeIcon icon={faExclamationTriangle} /> {errors.confirm_password.message}
                                                                </Form.Control.Feedback>
                                                            }
                                                        </Form.Group>
                                                    </>
                                            }


                                            {
                                                branchesLoading ?
                                                    <Loader />
                                                    :
                                                    branchesError ?
                                                        <Alert variant="danger">{branchesError}</Alert>
                                                        :
                                                        <>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <Form.Group controlId="user-branch">
                                                                        <Form.Label>Depot</Form.Label>
                                                                        <FormControl
                                                                            ref={register}
                                                                            as="select"
                                                                            name="branchcode"
                                                                            defaultValue={userData.branchcode}>
                                                                            {
                                                                                branchesData.map((branch, i) => (
                                                                                    <option key={`branch-${i}`}
                                                                                            value={branch.branchcode}>
                                                                                        {branch.name}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        </FormControl>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="col-12">
                                                                    <Form.Label>Depot Access</Form.Label>
                                                                </div>
                                                                <div className="col-12">
                                                                    {
                                                                        branchesData.map((branch, i) => {
                                                                            return (
                                                                                <Form.Group key={`branch-checkbox-${i}`} controlId={`branch-check-${branch.name}`}>
                                                                                    <Form.Check custom type="checkbox" ref={register} name={`branch-${branch.branchcode}`}
                                                                                                defaultChecked={!!_.get(userData, 'userbranches', []).find(b => b.branchcode === branch.branchcode)}
                                                                                                label={branch.name} />
                                                                                </Form.Group>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>

                                                            <Form.Group>
                                                            </Form.Group>
                                                        </>
                                            }

                                            <Form.Group controlId="user-carrier">
                                                <Form.Label>Carrier</Form.Label>
                                                {
                                                    carriersLoading ?
                                                            <Loader />
                                                        :
                                                            carriersError ?
                                                                    <Alert variant="danger">{carriersError}</Alert>
                                                                :
                                                                    <FormControl
                                                                        ref={register}
                                                                        as="select"
                                                                        name="carriercode"
                                                                        defaultValue={userData.carriercode}>
                                                                        {
                                                                            carriersData.map((carrier, i) => (
                                                                                <option key={`branch-${i}`}
                                                                                        value={carrier.carriercode}>
                                                                                    {carrier.name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </FormControl>
                                                }
                                            </Form.Group>

                                            <Form.Group controlId="user-active">
                                                <Form.Label>Active</Form.Label>
                                                <Form.Group controlId="user-active">
                                                    <Form.Check custom type="checkbox" ref={register} name="active"
                                                                defaultChecked={_.get(userData, 'active', false)}
                                                                label="Set whether this user is active on the system" />
                                                </Form.Group>
                                            </Form.Group>

                                            <Form.Group controlId="user-administrator">
                                                <Form.Label>Administrator</Form.Label>
                                                <Form.Group controlId="user-administrator">
                                                    <Form.Check custom type="checkbox" ref={register}
                                                                name="administrator"
                                                                defaultChecked={_.get(userData, 'administrator', false)}
                                                                label="Set whether this user is an administrator" />
                                                </Form.Group>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">

                                            <div className="row">
                                                <div className="col-12">
                                                    <Form.Label>Access</Form.Label>
                                                    <span onClick={() => selectAllAccess()} className="user-access-select-all float-right">Select all</span>
                                                </div>
                                                {
                                                    userData.access.map((item, i) => (
                                                        <div key={`user-access-${i}`}
                                                             className="col-sm-6 col-md-12 col-lg-6">
                                                            <Form.Group
                                                                controlId={`user-access-${item.name}`}>
                                                                <Form.Check custom type="checkbox" ref={register}
                                                                            name={`access.${item.name}`}
                                                                            defaultChecked={item.value}
                                                                            label={item.label} />
                                                            </Form.Group>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    <div className="form-buttons">
                                        <div className="row">
                                            <div className="col form-buttons-left">
                                                {
                                                    !isNewUser &&
                                                        <>
                                                            <Button variant="danger" disabled={formSubmitting} onClick={() => setDeleteModalShow(true)}>Delete</Button>
                                                            <ConfirmModal
                                                                show={deleteModalShow}
                                                                closeModal={() => {setDeleteError(null); setDeleteModalShow(false);}}
                                                                onConfirm={() => confirmDeleteUser(userData.userid)}
                                                                confirmText="Delete"
                                                                confirmVariant="danger"
                                                                confirmLoading={deleteLoading}
                                                                alert={!!deleteError ? <Alert variant="danger">{deleteError}</Alert> : false}
                                                                message="Are you sure you want to delete this user? This action cannot be undone." />
                                                        </>
                                                }
                                            </div>
                                            <div className="col-auto form-buttons-right">
                                                <Button as={Link} to="/users" variant="light" disabled={formSubmitting}>Cancel</Button>
                                                <FormButton type="submit" variant="primary"
                                                            loading={formSubmitting}>Save</FormButton>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </>
            }
        </>
    );
};

export default EditUserPage;
