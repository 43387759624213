import React from "react";
import {
    Button,
    ButtonToolbar
} from "react-bootstrap";
import TruckIconIn from "../TruckIconIn";
import TruckIconOut from "../TruckIconOut";
import {useCreateBooking} from "../../../util/bookings";
import CreateBookingSection from "../CreateBookingSection";

import "./styles.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";

const DirectionButton = ({variant, direction, active, onClick}) => (
    <Button variant={variant} onClick={onClick} className="btn-direction">
        {direction === 'IN' && <TruckIconIn active={active} />}
        {direction === 'OUT' && <TruckIconOut active={active} />}
        <span>{direction.toUpperCase()}</span>
    </Button>
);

const SelectContainerDirection = () => {

    const {bookingState, setDirection} = useCreateBooking();

    return (
        <CreateBookingSection title="Container Direction">
            <ButtonToolbar>
                <DirectionButton
                    variant={bookingState.direction === 'IN' ? 'primary' : 'light'}
                    onClick={() => setDirection('IN')}
                    active={bookingState.direction === 'IN'}
                    direction="IN" />

                <DirectionButton
                    variant={bookingState.direction === 'OUT' ? 'primary' : 'light'}
                    onClick={() => setDirection('OUT')}
                    active={bookingState.direction === 'OUT'}
                    direction="OUT" />
            </ButtonToolbar>
            <div className={`invalid-feedback ${!!bookingState.errors.direction ? 'd-block' : ''}`}>
                <FontAwesomeIcon icon={faExclamationTriangle} /> {bookingState.errors.direction}
            </div>
        </CreateBookingSection>
    );
};

export default SelectContainerDirection;
