import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import PageTitle from "../../components/layout/PageTitle";
import {Alert, Button, FormControl, InputGroup} from "react-bootstrap";
import Loader from "../../components/layout/Loader";
import {usePendingContainersData} from "../../util/data-hooks";
import {application} from "../../util/application";
import _ from "lodash";
import {useAuth} from "../../util/auth";
import {Link} from "react-router-dom";
import {faCheckCircle, faCopy} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const ContainerList = ({containersData, branchcode}) => {

    const [copiedContainer, setCopiedContainer] = useState("");

    const copyToClipboard = target => {
        document.getElementById(target).select();
        document.execCommand("copy");
        setCopiedContainer(target);

        setTimeout(() => {
            setCopiedContainer("");
        }, 4000);
    };

    return (
        <>
            <p>This depot has the following pending containers:</p>
            <ul className="pending-container-list">
                {
                    containersData.map((container, i) => {

                        const container_id = `c-${branchcode}-${i}`;

                        return (
                            <li key={`container-PC-${i}`}>
                                <InputGroup>
                                    <FormControl id={container_id} value={container} readOnly/>
                                    <InputGroup.Append>
                                        <Button variant="light"
                                                onClick={() => copyToClipboard(container_id)}><FontAwesomeIcon
                                            icon={faCopy}/></Button>
                                        {
                                            copiedContainer === container_id ?
                                                <InputGroup.Text>
                                                    <span className="container-copied text-success"><FontAwesomeIcon icon={faCheckCircle}/> Copied</span>
                                                </InputGroup.Text>
                                                :
                                                null
                                        }
                                    </InputGroup.Append>
                                </InputGroup>

                            </li>
                        );
                    })
                }
            </ul>
        </>
    );
};

ContainerList.propTypes = {
    containersData: PropTypes.array,
    branchcode: PropTypes.string
};


const PendingContainersPage = () => {
    const {user, userHasPermission} = useAuth();

    const userHasImportPending = useMemo(() => userHasPermission("importpendingcontainers"), [userHasPermission]);

    const [containersLoadingPC, containersDataPC, containersErrorPC] = usePendingContainersData("PC");
    const [containersLoadingSF, containersDataSF, containersErrorSF] = usePendingContainersData("SF");

    let userHasPC = false;
    let userHasSF = false;

    for (const userbranch of user.userbranches) {
        if (userbranch.branchcode === 'PC') userHasPC = true;
        if (userbranch.branchcode === 'SF') userHasSF = true;
    }

    return (
        <>
            <PageTitle title="Pending Containers">
                {userHasImportPending && <Button as={Link} to="/containers/import-pending" variant="primary">Import</Button>}
            </PageTitle>
            <div className="container-fluid">

                <div className="row">
                    {
                        userHasPC &&
                            <div className="col-md-6 mt-4 mt-md-0">
                                <h2>{_.get(application, "branch_names.PC", "")}</h2>
                                {
                                    containersLoadingPC ?
                                        <Loader/>
                                        :
                                        !containersErrorPC
                                            ?
                                            containersDataPC.length ?
                                                <ContainerList containersData={containersDataPC} branchcode={"PC"}/>
                                                :
                                                <Alert variant="warning">There are currently no pending containers</Alert>
                                            :
                                            <Alert variant="danger">Error fetching pending containers</Alert>
                                }
                            </div>
                    }
                    {
                        userHasSF &&
                            <div className="col-md-6 mt-4 mt-md-0">
                                <h2>{_.get(application, "branch_names.SF", "")}</h2>
                                {
                                    containersLoadingSF ?
                                        <Loader/>
                                        :
                                        !containersErrorSF
                                            ?
                                            containersDataSF.length ?
                                                <ContainerList containersData={containersDataSF} branchcode={"SF"}/>
                                                :
                                                <Alert variant="warning">There are currently no pending containers</Alert>
                                            :
                                            <Alert variant="danger">Error fetching pending containers</Alert>

                                }
                            </div>
                    }
                </div>

            </div>
        </>
    );
};

export default PendingContainersPage;
