import React, {useEffect, useMemo, useState} from 'react';
import _ from 'lodash';
import PageTitle from "../layout/PageTitle";
import {Alert} from "react-bootstrap";
import {Link, useLocation, useRouteMatch} from "react-router-dom";
import {useAuth} from "../../util/auth";
import {routes} from "../../routes";

const withAuth = (WrappedComponent) => (
    props => {
        const {checkAuth, checkAuthLoading, checkAuthError, user} = useAuth(),
              [authChecked, setAuthChecked] = useState(false),
              location = useLocation(),
              routeMatch = useRouteMatch(location.pathname);

        const route = useMemo(() => routes.find(route => _.defaultTo(routeMatch.path, false) === route.path), [routeMatch.path]);

        useEffect(() => {
            if (!authChecked) checkAuth();
            setAuthChecked(true);
        }, [checkAuth, authChecked]);

        if (authChecked && !checkAuthLoading) {
            if (!!checkAuthError) {
                return (
                    <div className="container mt-5">
                        <Alert variant="danger">Could not authorise user. Try <Link to={'/logout'}>logging in again.</Link></Alert>
                    </div>
                );
            }

            if (!!route) {
                if (route.admin_only) {
                    if (!_.get(user, 'administrator', false)) {
                        return (
                            <div className="container mt-5">
                                <Alert variant="danger">You do not have permission to view this page.</Alert>
                            </div>
                        );
                    }
                }

                if (!_.isNil(route.permission)) {
                    if (!_.get(user, `access.${route.permission}`, false)) {
                        return (
                            <div className="container mt-5">
                                <Alert variant="danger">You do not have permission to view this page.</Alert>
                            </div>
                        );
                    }
                }
            }

            return <WrappedComponent {...props} />;
        }

        return <PageTitle loading={true} />;
    }
);

export default withAuth;
