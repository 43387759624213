import React from "react";
import PropTypes from "prop-types";
import {useCreateBooking} from "../../../util/bookings";
import FieldSummary from '../FieldSummary';

import "./styles.scss";
import FormButton from "../../../components/forms/FormButton";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import {Link} from "react-router-dom";
import BookingStatusBadge from "../../../components/badges/BookingStatusBadge";
import {application} from "../../../util/application";

const bookingStatusesForPin = application.bookings_status_types.filter(status => status.showPin).map(status => status.statusCode);

const SaveBookingModal = ({carriersLoading, carriersData, branchesLoading, branchesData, show, handleClose}) => {
    const {saveBooking, saveLoading, saveData, resetBooking} = useCreateBooking();

    return (
        <Modal backdrop="static" show={show} onHide={handleClose} className="create-booking-save-modal">
            <Modal.Header>
                <Modal.Title as="h2">Confirm Booking Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    !!saveData ?
                        <>
                            <Alert variant="success">
                                <p>Booking saved successfully.</p>
                                {
                                    saveData.bookings.map(booking => {
                                        return (
                                            <p>
                                                Booking status: <BookingStatusBadge bookingstatus={booking.bookingstatus} noshow={booking.noshow} cancelled={booking.cancelled} /><br />
                                                {
                                                    bookingStatusesForPin.find(status => status === booking.bookingstatus)
                                                        && <>Booking PIN: <strong>{booking.bookingnoPin}</strong></>
                                                }
                                            </p>
                                        );
                                    })
                                }
                            </Alert>
                        </>
                        :
                        <>
                            <Alert variant="info">Please check that the following booking details are correct before saving.</Alert>
                            <FieldSummary
                                branchesData={branchesData}
                                branchesLoading={branchesLoading}
                                carriersData={carriersData}
                                carriersLoading={carriersLoading} />
                        </>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    !!saveData ?
                        <>
                            <Button as={Link} to="/bookings" variant="light">View Bookings</Button>
                            <Button variant="primary" onClick={resetBooking}>Create Another Booking</Button>
                        </>
                        :
                        <>
                            <Button variant="light" onClick={handleClose}>Cancel</Button>
                            <FormButton loading={saveLoading} variant="primary" onClick={saveBooking}>Save Booking</FormButton>
                        </>
                }
            </Modal.Footer>
        </Modal>
    );
};

SaveBookingModal.propTypes = {
    carriersLoading: PropTypes.bool.isRequired,
    carriersData: PropTypes.array,
    branchesLoading: PropTypes.bool.isRequired,
    branchesData: PropTypes.array,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default SaveBookingModal;
