import React from 'react';
import './styles.scss';


const Error404Page = ({location}) => (
    <div className="container-fluid">
        <div className="error-container">
            <div className="error-status">
                404
            </div>
            <div className="error-message">
                The page <code>{location.pathname}</code> could not be found.
            </div>
        </div>
    </div>
);

export default Error404Page;
