import React from "react";
import {
    Form
} from "react-bootstrap";
import {useCreateBooking} from "../../../util/bookings";

import "./styles.scss";
import CreateBookingSection from "../CreateBookingSection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";

const EnterContainerNumber = () => {

    const {bookingState, setContainerNo} = useCreateBooking();

    return (
        <CreateBookingSection title="Container Number">
            <Form.Group controlId="containerno">
                <Form.Control
                    name="containerno"
                    isInvalid={!!bookingState.errors.containerno}
                    value={!bookingState.containerno ? '' : bookingState.containerno}
                    onChange={(event) => setContainerNo(event.target.value)}
                />
                {
                    bookingState.errors.containerno &&
                    <Form.Control.Feedback type="invalid">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> {bookingState.errors.containerno}
                    </Form.Control.Feedback>
                }
            </Form.Group>
        </CreateBookingSection>
    );
};

export default EnterContainerNumber;
