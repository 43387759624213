import React from "react";
import PropTypes from 'prop-types';
import "./styles.scss";

const TruckIconOut = ({active}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`truck-direction-icon-out${active ? ' active' : ''}`} viewBox="0 0 647 521"
             aria-hidden="true" focusable="false" role="img">
            <path className="truck"
                d="M132.1,385.5H416.65a27.07,27.07,0,0,0,27.1-27.1V168.7a27.07,27.07,0,0,0-27.1-27.1H132.1A27.07,27.07,0,0,0,105,168.7V358.4A27.07,27.07,0,0,0,132.1,385.5Zm0-216.8H416.65V358.4H132.1ZM631.16,288.36l-49.54-49.54A54.23,54.23,0,0,0,543.26,223H484.4a13.59,13.59,0,0,0-13.55,13.55V412.68H298.68a60,60,0,0,0-85.28,9.57,60.94,60.94,0,1,0,0,75.63A60.72,60.72,0,0,0,321.8,460a59.62,59.62,0,0,0-3.73-20.32H485.76a68.29,68.29,0,0,0,2.37,35.91,66.89,66.89,0,0,0,47.08,43.27c44.72,10.84,84.69-22.78,84.69-65.71a71.89,71.89,0,0,0-1.35-13.55h14.9A13.59,13.59,0,0,0,647,426.07V326.64A54,54,0,0,0,631.16,288.36ZM166,493.9A33.88,33.88,0,1,1,199.85,460,33.87,33.87,0,0,1,166,493.9Zm94.85,0A33.88,33.88,0,1,1,294.7,460,33.87,33.87,0,0,1,260.83,493.9ZM498,250h45.31a26.68,26.68,0,0,1,19.14,8l46.24,46.24H498Zm54.2,243.9a40.65,40.65,0,1,1,40.65-40.65A40.66,40.66,0,0,1,552.15,493.9Zm67.75-81.3H606a67.07,67.07,0,0,0-107.72,0H498V331.3h122ZM186.08,331.3h13.54a6.8,6.8,0,0,0,6.78-6.78V202.58a6.8,6.8,0,0,0-6.78-6.78H186.08a6.8,6.8,0,0,0-6.78,6.78V324.52A6.8,6.8,0,0,0,186.08,331.3Zm81.3,0h13.54a6.8,6.8,0,0,0,6.78-6.78V202.58a6.8,6.8,0,0,0-6.78-6.78H267.38a6.8,6.8,0,0,0-6.78,6.78V324.52A6.8,6.8,0,0,0,267.38,331.3Zm81.29,0h13.56a6.8,6.8,0,0,0,6.77-6.78V202.58a6.8,6.8,0,0,0-6.77-6.78H348.67a6.8,6.8,0,0,0-6.77,6.78V324.52A6.8,6.8,0,0,0,348.67,331.3Z"/>
            <path className="gate"
                d="M231.77,50.75,39.77,1a30.88,30.88,0,0,0-7.83-1C14.8,0,0,14.42,0,33.18V521H32V33.18L31.94,32l191.8,49.69,0,50.27H256V82.92C256,67.7,246,54.44,231.77,50.75Z"/>
        </svg>
    );
};

TruckIconOut.propTypes = {
    active: PropTypes.bool.isRequired
};

TruckIconOut.defaultProps = {
    active: false
};

export default TruckIconOut;
