import _ from 'lodash';
import {routes} from "../routes";

export const menu = [
    {
        type: "link",
        title: "Dashboard",
        route: routes.find(r => r.path === "/")
    },
    {
        type: "group",
        title: "Bookings",
        children: [
            {
                type: "link",
                title: "Create Booking",
                route: routes.find(r => r.path === "/bookings/create")
            },
            {
                type: "link",
                title: "View Bookings",
                route: routes.find(r => r.path === "/bookings")
            }
        ]
    },
    {
        type: "group",
        title: "Containers",
        children: [
            {
                type: "link",
                title: "Pending Containers",
                route: routes.find(r => r.path === "/containers/pending")
            },
            {
                type: "link",
                title: "Import Pending Containers",
                route: routes.find(r => r.path === "/containers/import-pending")
            },
            {
                type: "link",
                title: "Release Enquiry",
                route: routes.find(r => r.path === "/containers/release-enquiry")
            },
            {
                type: "link",
                title: "Container Enquiry",
                route: routes.find(r => r.path === "/containers/container-enquiry")
            }
        ]
    },
    // {
    //     type: "group",
    //     title: "Reports",
    //     children: [
    //         {
    //             type: "link",
    //             title: "View All Bookings",
    //             route: routes.find(r => r.path === "/")
    //         },
    //         {
    //             type: "link",
    //             title: "View Pending Containers",
    //             route: routes.find(r => r.path === "/")
    //         },
    //         {
    //             type: "link",
    //             title: "Container Enquiry",
    //             route: routes.find(r => r.path === "/container-enquiry")
    //         },
    //         {
    //             type: "link",
    //             title: "Release Enquiry",
    //             route: routes.find(r => r.path === "/")
    //         },
    //     ]
    // },
    {
        type: "group",
        title: "Users",
        children: [
            {
                type: "link",
                title: "Create User",
                route: routes.find(r => r.path === "/users/create")
            },
            {
                type: "link",
                title: "View Users",
                route: routes.find(r => r.path === "/users")
            }
            // ,
            // {
            //     type: "link",
            //     title: "Branch Administration",
            //     route: routes.find(r => r.path === "/")
            // }
        ]
    }
];

export const getUserMenuItems = (menu, user) => {

    const user_menu = [];

    const user_is_admin = _.get(user, 'administrator', false);

    menu.forEach(item => {
        if (item.type === 'group') {
            const group_children = [];

            if (!!item.children) {
                item.children.forEach(child_item => {

                    if (_.get(child_item, 'route.admin_only', false) && !user_is_admin) {

                    } else {
                        if (!!child_item.route.permission) {
                            const has_permission = _.get(user, `access.${child_item.route.permission}`, false);
                            if (has_permission) group_children.push(child_item);
                        } else {
                            group_children.push(child_item);
                        }
                    }

                });
            }
            if (group_children.length) user_menu.push({...item, children: group_children});
        } else {
            if (!!item.route.permission) {
                const has_permission = _.get(user, `access.${item.route.permission}`, false);
                if (has_permission) user_menu.push(item);
            } else {
                user_menu.push(item);
            }
        }
    });

    return user_menu;

};
