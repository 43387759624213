import React, {useState} from "react";
import PropTypes from "prop-types";
import {Alert, Button, Form, Modal} from "react-bootstrap";

import './styles.scss';
import FormButton from "../../forms/FormButton";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {APICall} from "../../../util/api";
import {useAuth} from "../../../util/auth";
import axios from "axios";
import {runScrollToTop} from "../../../util/scroll-to-top";

const ChangePasswordSchema = yup.object().shape({
    password: yup
        .string()
        .min(8, 'Password must contain a minimum of 8 characters')
        .max(20, 'Password must contain a maximum of 20 characters')
        .required('Password is required'),
    confirm_password: yup
        .string()
        .required('You must confirm the password')
        .test('passwords-match', 'Passwords do not match', function (value) {
            return this.parent.password === value;
        })
});

const fieldLabels = {
    password: "Password",
    confirm_password: "Confirm Password"
};

const ChangePasswordModal = ({show, closeModal, userlogin, setSuccessMessage}) => {

    const {user} = useAuth();
    const {register, errors, handleSubmit, setError} = useForm({validationSchema: ChangePasswordSchema});

    const [saving, setSaving] = useState(false);

    const onSubmit = ({password}) => {
        setSaving(true);

        APICall({
            method: 'post',
            url: '?MessageType=UpdateUser',
            data: {
                id: user.id,
                login: userlogin,
                password
            }
        })
            .then(response => {
                setSuccessMessage('User password changed successfully');
                setSaving(false);
                closeModal();
                runScrollToTop();
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    const errors = _.get(err, 'response.data.errors', []);

                    if (!_.isEmpty(errors)) {
                        errors.forEach(error => {
                            setError(error.field, 'error', error.error);
                        });
                    } else {
                        setError('Error', 'error', 'User password could not be updated');
                    }

                    setSaving(false);
                }
            });
    };

    return (
        <Modal
            show={show}
            backdrop="static"
            centered>
            <Modal.Header>
                <Modal.Title as="h2">Change Password</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <p>Change password for user <strong>{userlogin}</strong></p>
                    {
                        !_.isEmpty(errors) &&
                            <Alert variant="danger" className="form-errors">
                                <p><strong>Please correct the following errors:</strong></p>
                                <ul>
                                    {
                                        Object.keys(errors).map((key, i) => (
                                            <li key={`error-${i}`}>
                                                <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                                                <strong>{fieldLabels[key]}:</strong>{' '}
                                                {errors[key].message}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </Alert>
                    }
                    <Form.Group controlId="user-password">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            ref={register}
                            name="password"
                            type="password"
                            isInvalid={!!_.get(errors, 'password.message', false)}
                            defaultValue=""
                        />
                        {
                            errors.password &&
                                <Form.Control.Feedback type="invalid">
                                    <FontAwesomeIcon icon={faExclamationTriangle} /> {errors.password.message}
                                </Form.Control.Feedback>
                        }
                    </Form.Group>
                    <Form.Group controlId="user-confirm-password">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                            ref={register}
                            name="confirm_password"
                            type="password"
                            isInvalid={!!_.get(errors, 'confirm_password.message', false)}
                            defaultValue=""
                        />
                        {
                            errors.confirm_password &&
                                <Form.Control.Feedback type="invalid">
                                    <FontAwesomeIcon icon={faExclamationTriangle} /> {errors.confirm_password.message}
                                </Form.Control.Feedback>
                        }
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={closeModal}>Cancel</Button>
                    <FormButton type="submit" loading={saving} variant="primary">Save</FormButton>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

ChangePasswordModal.propTypes = {
    show: PropTypes.bool,
    setSuccessMessage: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    userlogin: PropTypes.string
};

ChangePasswordModal.defaultProps = {
    show: false
};

export default ChangePasswordModal;
