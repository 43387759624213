import React from "react";
import {
    Form,
    FormControl
} from "react-bootstrap";
import {useCreateBooking} from "../../../util/bookings";

import "./styles.scss";
import CreateBookingSection from "../CreateBookingSection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";

const EnterQuantity = () => {

    const {bookingState, setQty} = useCreateBooking();

    return (
        <CreateBookingSection title="Container Quantity">
            <div className="row">
                <div className="col-6 col-md-4">
                    <Form.Group controlId="qty">
                        <FormControl
                            as="select"
                            name="qty"
                            disabled={!!bookingState.containerno}
                            isInvalid={!!bookingState.errors.qty}
                            value={bookingState.qty}
                            onChange={(event) => setQty(event.target.value)}>
                            {
                                new Array(6).fill(0).map((branch, i) => (
                                    <option key={`qty-${i}`}
                                            value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))
                            }
                        </FormControl>
                        {
                            bookingState.errors.qty &&
                            <Form.Control.Feedback type="invalid">
                                <FontAwesomeIcon icon={faExclamationTriangle} /> {bookingState.errors.qty}
                            </Form.Control.Feedback>
                        }
                    </Form.Group>
                </div>
            </div>
        </CreateBookingSection>
    );
};

export default EnterQuantity;
