export const application = {
    node_env: process.env.NODE_ENV,
    public_url: process.env.PUBLIC_URL,
    api_base_url: `${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_PRODUCTION_DOMAIN : ''}${process.env.REACT_APP_API_BASE_URL}`,
    application_title: process.env.REACT_APP_APPLICATION_TITLE,
    application_subtitle: process.env.REACT_APP_APPLICATION_SUBTITLE,
    application_subtitle_mobile: process.env.REACT_APP_APPLICATION_SUBTITLE_MOBILE,
    default_error: 'An error occurred while fetching data',
    branch_names: {
        SF: 'Dunedin Depot',
        PC: 'Port Chalmers Terminal'
    },
    dashboard_refresh_interval: 60000,
    bookings_refresh_interval: 60000,
    bookings_status_types: [
        {
            label: "Pending",
            statusCode: "PND",
            variant: "container-pending",
            showPin: false
        },
        {
            label: "Active",
            statusCode: "ACT",
            variant: "container-active",
            showPin: true
        },
        {
            label: "Early",
            statusCode: "ERL",
            variant: "container-early",
            showPin: true
        },
        {
            label: "Late",
            statusCode: "LAT",
            variant: "container-late",
            showPin: true
        },
        {
            label: "Expired",
            statusCode: "EXP",
            variant: "container-expired",
            showPin: false
        },
        {
            label: "Complete",
            statusCode: "COM",
            variant: "container-complete",
            showPin: false
        },
        {
            label: "Cancelled",
            statusCode: "CAN",
            variant: "container-cancelled",
            showPin: false
        },
        {
            label: "No show",
            statusCode: "NSH",
            variant: "container-noshow",
            showPin: false
        }
    ],
    bookings_columns: {
        bookingstatus: {
            label: 'Status',
            value: true,
            can_edit: false
        },
        direction: {
            label: 'Direction',
            value: true,
            can_edit: false
        },
        bookingnoPin: {
            label: 'Booking No.',
            value: true,
            can_edit: false
        },
        branchcode: {
            label: 'Depot',
            value: true,
            can_edit: false
        },
        bookingreference: {
            label: 'Booking Ref',
            value: true,
            can_edit: true
        },
        containerno: {
            label: 'Container',
            value: true,
            can_edit: true
        },
        regono: {
            label: 'Truck Rego',
            value: false,
            can_edit: true
        },
        isocode: {
            label: 'ISO',
            value: false,
            can_edit: true
        },
        dateslot: {
            label: 'Date / Slot',
            value: true,
            can_edit: true
        },
        carriercode: {
            label: 'Carrier',
            value: true,
            can_edit: true
        },
        createdbyname: {
            label: 'Created By',
            value: false,
            can_edit: true
        },
        commodity: {
            label: 'Commodity',
            value: false,
            can_edit: true
        },
        shippingoperator: {
            label: 'Shipping Operator',
            value: false,
            can_edit: true
        },
        actions: {
            label: 'Actions',
            value: true,
            can_edit: true
        }
    },
    user_permissions: [
        {
            name: 'acceptanceadviceenquiry',
            value: false,
            label: 'Acceptance advice enquiry'
        },
        {
            name: 'containeracceptanceenquiry',
            value: false,
            label: 'Container acceptance enquiry'
        },
        {
            name: 'containerdailylog',
            value: false,
            label: 'Container daily log'
        },
        {
            name: 'containerdetailsenquiry',
            value: false,
            label: 'Container details enquiry'
        },
        {
            name: 'containerenquiry',
            value: false,
            label: 'Container enquiry'
        },
        {
            name: 'containerhistory',
            value: false,
            label: 'Container history'
        },
        {
            name: 'invoiceenquiry',
            value: false,
            label: 'Invoice enquiry'
        },
        {
            name: 'manualestimateenquiry',
            value: false,
            label: 'Manual estimate enquiry'
        },
        {
            name: 'releaseenquiry',
            value: false,
            label: 'Release enquiry'
        },
        {
            name: 'releaseentry',
            value: false,
            label: 'Release entry'
        },
        {
            name: 'repairquoteenquiry',
            value: false,
            label: 'Repair quote enquiry'
        },
        {
            name: 'stockenquiry',
            value: false,
            label: 'Stock enquiry'
        },
        {
            name: 'stocksummary',
            value: false,
            label: 'Stock summary'
        }
    ],
    user_access: [
        {
            name: 'createbooking',
            value: false,
            label: 'Create booking'
        },
        {
            name: 'importpendingcontainers',
            value: false,
            label: 'Import pending containers'
        },
        {
            name: 'getbookings',
            value: false,
            label: 'Get bookings'
        },
        {
            name: 'getbranches',
            value: false,
            label: 'Get branches'
        },
        {
            name: 'getreferencedata',
            value: false,
            label: 'Get reference data'
        },
        {
            name: 'getcarriers',
            value: false,
            label: 'Get carriers'
        },
        {
            name: 'getpendingcontainers',
            value: false,
            label: 'Get pending containers'
        },
        {
            name: 'getslots',
            value: false,
            label: 'Get slots'
        },
        {
            name: 'getuserdetails',
            value: false,
            label: 'Get user details'
        },
        {
            name: 'prearrivalcontainerenquiry',
            value: false,
            label: 'Pre-arrival container enquiry'
        },
        {
            name: 'releaseenquiry',
            value: false,
            label: 'Release enquiry'
        },
        {
            name: 'updatebooking',
            value: false,
            label: 'Update booking'
        },
        {
            name: 'validatebooking',
            value: false,
            label: 'Validate booking'
        },
        {
            name: 'version',
            value: false,
            label: 'Version'
        }
    ]
};
