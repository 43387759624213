import React from "react";
import PropTypes from "prop-types";
import {getBranchName, getCarrierName} from "../../../util/data-hooks";
import {useCreateBooking} from "../../../util/bookings";

import "./styles.scss";

const FieldSummary = ({branchesLoading, branchesData, carriersLoading, carriersData}) => {
    const {bookingState, fieldLabels} = useCreateBooking();

    return (
        <dl>
            {
                !!bookingState.branchcode &&
                <>
                    <dt>{fieldLabels.branchcode}</dt>
                    <dd>
                        {!branchesLoading && !!branchesData && getBranchName(branchesData, bookingState.branchcode)}
                    </dd>
                </>
            }
            {
                !!bookingState.carriercode &&
                <>
                    <dt>{fieldLabels.carriercode}</dt>
                    <dd>
                        {!carriersLoading && !!carriersData && getCarrierName(carriersData, bookingState.carriercode)}
                    </dd>
                </>
            }
            {
                !!bookingState.direction &&
                <>
                    <dt>{fieldLabels.direction}</dt>
                    <dd>{bookingState.direction.toUpperCase()}</dd>
                </>
            }
            {
                !!bookingState.qty &&
                <>
                    <dt>{fieldLabels.qty}</dt>
                    <dd>{bookingState.qty}</dd>
                </>
            }
            {
                !!bookingState.bookingdate &&
                <>
                    <dt>{fieldLabels.bookingdate}</dt>
                    <dd>{bookingState.bookingdate.date.day} {bookingState.bookingdate.date.month} {bookingState.bookingdate.date.year}</dd>
                </>
            }
            {
                !!bookingState.bookingslot &&
                <>
                    <dt>{fieldLabels.bookingslot}</dt>
                    <dd>{bookingState.bookingslot.description}</dd>
                </>
            }
            {
                !!bookingState.reference &&
                <>
                    <dt>{fieldLabels.reference}</dt>
                    <dd>{bookingState.reference}</dd>
                </>
            }
            {
                !!bookingState.containerno &&
                <>
                    <dt>{fieldLabels.containerno}</dt>
                    <dd>{bookingState.containerno}</dd>
                </>
            }
            {
                !!bookingState.doorposition &&
                <>
                    <dt>{fieldLabels.doorposition}</dt>
                    <dd>{bookingState.doorposition}</dd>
                </>
            }
            {
                !!bookingState.isocode &&
                <>
                    <dt>{fieldLabels.isocode}</dt>
                    <dd>{bookingState.isocode}</dd>
                </>
            }
            {
                !!bookingState.pinsposition &&
                <>
                    <dt>{fieldLabels.pinsposition}</dt>
                    <dd>{bookingState.pinsposition}</dd>
                </>
            }
            {
                !!bookingState.loadstatus &&
                <>
                    <dt>{fieldLabels.loadstatus}</dt>
                    <dd>{bookingState.loadstatus}</dd>
                </>
            }
            {
                !!bookingState.regono &&
                <>
                    <dt>{fieldLabels.regono}</dt>
                    <dd>{bookingState.regono}</dd>
                </>
            }
        </dl>
    );
};

FieldSummary.propTypes = {
    carriersLoading: PropTypes.bool.isRequired,
    carriersData: PropTypes.array,
    branchesLoading: PropTypes.bool.isRequired,
    branchesData: PropTypes.array
};

export default FieldSummary;
