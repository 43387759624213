import React from 'react';
import {Link} from 'react-router-dom';

import './styles.scss';
import PropTypes from "prop-types";
import {application} from "../../../util/application";

const AppLogo = ({inverted, subtitle, subtitleMobile}) => (
    <Link to="/" className={`app-logo${inverted === true ? ' app-logo-inverted' : ''}`}>
        <div className="app-logo-image">
            <img src={`${application.public_url}/portotago_colour_single_row_lowres.png`} alt="Port Otago" />
        </div>
        {
            subtitle &&
                <div className="app-logo-subtitle">
                    {subtitle}
                </div>
        }
        {
            subtitleMobile &&
            <div className="app-logo-subtitle-mobile">
                {subtitleMobile}
            </div>
        }
    </Link>
);

AppLogo.propTypes = {
    inverted: PropTypes.bool,
    subtitle: PropTypes.string,
    subtitleMobile: PropTypes.string
};

AppLogo.defaultProps = {
    inverted: false
};

export default AppLogo;
