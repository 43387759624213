import React, {useCallback, useState} from 'react';
import PropTypes from "prop-types";
import Header from '../Header';
import ContentContainer from '../ContentContainer';
import Sidebar from "../Sidebar";
import {ScrollToTop} from '../../../util/scroll-to-top';

import './styles.scss';

const MainLayout = ({routes}) => {

    const [sidebarOpen, setSidebarOpen] = useState(false),
          toggleSidebar = useCallback(() => setSidebarOpen(!sidebarOpen), [sidebarOpen, setSidebarOpen]),
          closeSidebar = useCallback(() => setSidebarOpen(false), [setSidebarOpen]);

    return (
        <>
            <ScrollToTop />
            <div className="main-layout">
                <Header sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
                <ContentContainer routes={routes}/>
            </div>
            <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
        </>
    );
};

MainLayout.propTypes = {
    routes: PropTypes.array.isRequired
};

export default MainLayout;
