import React from "react";
import PropTypes from "prop-types";

import {useDocumentTitle} from "../../../util/document-title";
import Loader from "../Loader";

import './styles.scss';

const PageTitle = ({title, children, loading}) => {

    useDocumentTitle(title);

    return (
        <div className="page-title">
            <div className="container-fluid">
                <div className="row">
                    {
                        loading ?
                            <div className="col">
                                <Loader />
                            </div>
                            :
                            <>
                                <div className="col page-title-col-left">
                                    <h1>{title}</h1>
                                </div>
                                {
                                    children &&
                                    <div className="col-auto page-title-col-right">
                                        {children}
                                    </div>
                                }
                            </>

                    }
                </div>
            </div>
        </div>
    );
};

PageTitle.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    loading: PropTypes.bool
};

PageTitle.defaultProps = {
    loading: false
};

export default PageTitle;
