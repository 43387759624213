import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

const TruckIconIn = ({active}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`truck-direction-icon-in${active ? ' active' : ''}`} aria-hidden="true" focusable="false"
             role="img" viewBox="0 0 644 521">
            <path className="truck"
                d="M27.1,385.5H311.65a27.07,27.07,0,0,0,27.1-27.1V168.7a27.07,27.07,0,0,0-27.1-27.1H27.1A27.07,27.07,0,0,0,0,168.7V358.4A27.07,27.07,0,0,0,27.1,385.5Zm0-216.8H311.65V358.4H27.1ZM526.16,288.36l-49.54-49.54A54.23,54.23,0,0,0,438.26,223H379.4a13.59,13.59,0,0,0-13.55,13.55V412.68H193.68a60,60,0,0,0-85.28,9.57,60.94,60.94,0,1,0,0,75.63A60.72,60.72,0,0,0,216.8,460a59.62,59.62,0,0,0-3.73-20.32H380.76a68.29,68.29,0,0,0,2.37,35.91,66.89,66.89,0,0,0,47.08,43.27c44.72,10.84,84.69-22.78,84.69-65.71a71.89,71.89,0,0,0-1.35-13.55h14.9A13.59,13.59,0,0,0,542,426.07V326.64A54,54,0,0,0,526.16,288.36ZM61,493.9A33.88,33.88,0,1,1,94.85,460,33.87,33.87,0,0,1,61,493.9Zm94.85,0A33.88,33.88,0,1,1,189.7,460,33.87,33.87,0,0,1,155.83,493.9ZM393,250h45.31a26.68,26.68,0,0,1,19.14,8l46.24,46.24H393Zm54.2,243.9a40.65,40.65,0,1,1,40.65-40.65A40.66,40.66,0,0,1,447.15,493.9Zm67.75-81.3H501a67.07,67.07,0,0,0-107.72,0H393V331.3h122ZM81.08,331.3H94.62a6.8,6.8,0,0,0,6.78-6.78V202.57a6.8,6.8,0,0,0-6.78-6.77H81.08a6.8,6.8,0,0,0-6.78,6.77v122A6.8,6.8,0,0,0,81.08,331.3Zm81.3,0h13.54a6.8,6.8,0,0,0,6.78-6.78V202.57a6.8,6.8,0,0,0-6.78-6.77H162.38a6.8,6.8,0,0,0-6.78,6.77v122A6.8,6.8,0,0,0,162.38,331.3Zm81.29,0h13.56a6.8,6.8,0,0,0,6.77-6.78V202.57a6.79,6.79,0,0,0-6.77-6.77H243.67a6.79,6.79,0,0,0-6.77,6.77v122A6.8,6.8,0,0,0,243.67,331.3Z"/>
            <path className="gate"
                d="M412.23,50.75,604.23,1a30.88,30.88,0,0,1,7.83-1C629.2,0,644,14.42,644,33.18V521H612V33.18l.06-1.14L420.26,81.73l0,131.27H388V82.92C388,67.7,398,54.44,412.23,50.75Z"/>
        </svg>
    );
};

TruckIconIn.propTypes = {
    active: PropTypes.bool.isRequired
};

TruckIconIn.defaultProps = {
    active: false
};

export default TruckIconIn;
