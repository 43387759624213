import React, {useCallback, useState} from "react";
import {
    Alert,
    Form
} from "react-bootstrap";
import {useCreateBooking} from "../../../util/bookings";

import "./styles.scss";
import CreateBookingSection from "../CreateBookingSection";
import _ from "lodash";
import {APICall, APIError} from "../../../util/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";
import {useAuth} from "../../../util/auth";

const EnterReleaseNumber = () => {

    const {user} = useAuth(),
          {bookingState, setReference, setReferenceValid, removeFieldError, setFieldError, fieldLabels, setISOCodeOptions, setLoadStatusOptions} = useCreateBooking(),
          [checkRefLoading, setCheckRefLoading] = useState(false);

    const changeHandler = (event) => {
        removeFieldError('reference');
        setReference(event.target.value);
    };

    const blurHandler = useCallback(() => {

        if (!bookingState.reference) return;

        setCheckRefLoading(true);

        APICall({
            method: 'post',
            url: '?MessageType=GetReferenceData',
            data: {id: user.id, branchcode: bookingState.branchcode, direction: bookingState.direction, reference: bookingState.reference}
        })
            .then(response => {

                let iso_options = _.get(response, 'data.isos', []) || [];
                let loadstatus_options = _.get(response, 'data.loadstatuses', []) || [];
                let size_options = _.get(response, 'data.sizes', []) || [];
                let referencelines = _.get(response, 'data.referencelines', []) || [];

                if (_.isEmpty(iso_options)) iso_options = referencelines.map(item => item.isocode);
                if (_.isEmpty(loadstatus_options)) loadstatus_options = referencelines.map(item => item.loadstatus);
                if (_.isEmpty(size_options)) size_options = referencelines.map(item => item.sizecode);


                setISOCodeOptions(iso_options);
                setLoadStatusOptions(loadstatus_options);
                setReferenceValid(true);
                setCheckRefLoading(false);
            })
            .catch(err => {
                const data = _.get(err, 'response.data.errors[0]', undefined);
                if (!!data) {
                    setFieldError({field: 'reference', error: `${data.field !== 'reference' ? fieldLabels[data.field] + ' - ' : ''} ${data.error}`});
                } else {
                    setFieldError({field: 'reference', error: APIError(err.response)});
                }
                setCheckRefLoading(false);
            });

    }, [
        bookingState.branchcode,
        bookingState.direction,
        bookingState.reference,
        fieldLabels,
        setISOCodeOptions,
        setLoadStatusOptions,
        setReferenceValid,
        setCheckRefLoading,
        setFieldError,
        user.id
    ]);

    return (
        <CreateBookingSection title="Release Number">
            {
                !bookingState.branchcode ?
                    <Alert variant="warning">Select a depot before entering a release number</Alert>
                    :
                    <Form.Group controlId="releaseno">
                        <Form.Control
                            name="releaseno"
                            isInvalid={!!bookingState.errors.reference}
                            value={!bookingState.reference ? '' : bookingState.reference}
                            onChange={changeHandler}
                            onBlur={blurHandler}
                            disabled={checkRefLoading}
                        />
                        {
                            bookingState.errors.reference &&
                            <Form.Control.Feedback type="invalid">
                                <FontAwesomeIcon icon={faExclamationTriangle} /> {bookingState.errors.reference}
                            </Form.Control.Feedback>
                        }
                    </Form.Group>
            }
        </CreateBookingSection>
    );
};

export default EnterReleaseNumber;
