import React from 'react';
import PropTypes from "prop-types";
import {Switch, Route} from 'react-router-dom';

import './styles.scss';
import Footer from "../Footer";

const ContentContainer = ({routes}) => (
    <div className="content-container">
        <div className="content">
            <Switch>
                {routes.map((route, i) => <Route key={`route-${i}`} {...route} />)}
            </Switch>
        </div>
        <Footer />
    </div>
);

ContentContainer.propTypes = {
    routes: PropTypes.array.isRequired
};

export default ContentContainer;
