import {useLayoutEffect} from 'react';

export const ScrollToTop = () => {
    useLayoutEffect(() => {
        runScrollToTop();
    }, []);
    return null;
};

export const runScrollToTop = () => {
    try {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    } catch (error) {
        window.scrollTo(0, 0);
    }
};
