import _ from "lodash";


export function getQueryParams(search, pick = []) {
    const params = {};

    new URLSearchParams(search).forEach((value, key) => {
        if (!_.isEmpty(pick)) {
            if (pick.find(element => element === key)) params[key] = value;
        } else {
            params[key] = value;
        }
    });

    return params;
}
