import React from "react";
import moment from "moment";

import {Link} from "react-router-dom";
import {application} from "../../../util/application";

import {menu} from "../../../util/menu";

import "./styles.scss";

const Footer = () => (
    <footer className="footer">
        <div className="footer-inner">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md d-flex justify-content-center justify-content-md-start align-items-center flex-column flex-md-row">
                        <div className="nav">
                            {
                                menu.map((item, i) => {
                                    if (item.route) {
                                        return (
                                            <div className="nav-item" key={`footer-link-${i}`}>
                                                <Link className="nav-link" to={item.route.path}>{item.title}</Link>
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            }
                            {/*<div className="nav-item">*/}
                            {/*    <Link className="nav-link" to="/about">About {application.application_title}</Link>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="col-md-auto d-flex justify-content-center justify-content-md-end align-items-center">
                        <div className="mr-2">&copy; {application.application_title} {moment().format("YYYY")}</div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
