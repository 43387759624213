import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Nav, NavDropdown} from "react-bootstrap";
import AppLogo from "../../layout/AppLogo";
import {menu, getUserMenuItems} from "../../../util/menu";
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './styles.scss';
import {application} from "../../../util/application";
import {faUser} from "@fortawesome/pro-regular-svg-icons";
import {useAuth} from "../../../util/auth";

const Header = ({sidebarOpen, toggleSidebar}) => {

    const {user} = useAuth();
    const user_menu = getUserMenuItems(menu, user);

    return (
        <>
            <header className={`header ${sidebarOpen ? ' sidebar-open' : ''}`}>
                <div className="container-fluid">
                    <div className="header-app-title">
                        <AppLogo subtitle={application.application_subtitle} subtitleMobile={application.application_subtitle_mobile} />
                    </div>
                    <div className="header-sidebar-toggle">
                        <button className={`hamburger hamburger--collapse ${sidebarOpen ? ' is-active' : ''}`} type="button"
                                onClick={toggleSidebar}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner" />
                            </span>
                        </button>
                    </div>
                    <div className="header-navigation">
                        <ul className="nav">
                            {
                                !!user && user_menu.map((item, g) => {
                                    return (
                                        item.type === 'group' && item.children
                                            ?
                                            <NavDropdown title={item.title} id={`header-dropdown-${g}`}
                                                         key={`header-dropdown-group-${g}`}>
                                                {
                                                    item.children.map((child, c) => (
                                                        <NavDropdown.Item as={Link} to={child.route.path}
                                                                          key={`header-dropdown-child-${g}-${c}`}>{child.title}</NavDropdown.Item>
                                                    ))
                                                }
                                            </NavDropdown>
                                            :
                                            <Nav.Item key={`header-dropdown-group-${g}`}><Nav.Link as={Link}
                                                                to={item.route.path}>{item.title}</Nav.Link></Nav.Item>
                                    );
                                })
                            }
                        </ul>
                    </div>
                    {
                        !!user &&
                            <div className="header-user">
                                <Dropdown alignRight>
                                    <Dropdown.Toggle size="sm" variant="secondary" id="header-dropdown-user">
                                        <FontAwesomeIcon icon={faUser} /><span className="header-user-name">{user.name.length > 8 ? `${user.name.substring(0, 7)}...` : user.name}</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {/*<Dropdown.Item onClick={() => setShowChangePasswordModal(true)}>Change password</Dropdown.Item>*/}
                                        <Dropdown.Item as={Link} to={'/logout'}>Log out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                    }
                </div>
            </header>
        </>
    );
};

Header.propTypes = {
    sidebarOpen: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired
};

export default Header;
