import React, {useState} from "react";
import "./styles.scss";
import PageTitle from "../../components/layout/PageTitle";
import {useAuth} from "../../util/auth";
import {useForm} from "react-hook-form";
import {getBranchName, useBranchesData} from "../../util/data-hooks";
import {APICall} from "../../util/api";
import _ from "lodash";
import axios from "axios";
import Loader from "../../components/layout/Loader";
import {Alert, Form, FormControl, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";
import FormButton from "../../components/forms/FormButton";

const ContainerEnquiryPage = () => {
    const {user} = useAuth();
    const {register, handleSubmit, errors, setError} = useForm();
    const [branchesLoading, branchesData, branchesError] = useBranchesData(true);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [enquiryData, setEnquiryData] = useState(null);

    const onSubmit = data => {
        setFormSubmitting(true);
        setEnquiryData(null);

        APICall({
            method: "post",
            url: "?MessageType=PreArrivalContainerEnquiry",
            data: {...data, id: user.id}
        })
            .then(response => {
                setEnquiryData(_.get(response, "data.containers", []));
                setFormSubmitting(false);
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    const errors = _.get(err, "response.data.errors", []);

                    if (!_.isEmpty(errors)) {
                        errors.forEach(error => {
                            setError(error.field, "error", error.error);
                        });
                    }

                    setFormSubmitting(false);
                }
            });
    };

    return (
        <>
            <PageTitle title="Pre-Arrival Container Enquiry"/>
            <div className="container-fluid">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4">
                            {
                                branchesLoading ?
                                    <Loader/>
                                    :
                                    branchesError ?
                                        <Alert variant="danger">{branchesError}</Alert>
                                        :
                                        <Form.Group controlId="branchcode">
                                            <Form.Label>Depot</Form.Label>
                                            <FormControl
                                                ref={register({required: "You must select a depot"})}
                                                as="select"
                                                name="branchcode"
                                                isInvalid={!!errors.branchcode}
                                                defaultValue={""}>
                                                <option value="">Select...</option>
                                                {
                                                    branchesData.map((branch, i) => (
                                                        <option key={`branch-${i}`}
                                                                value={branch.branchcode}>
                                                            {branch.name}
                                                        </option>
                                                    ))
                                                }
                                            </FormControl>
                                            {
                                                errors.branchcode &&
                                                <Form.Control.Feedback type="invalid">
                                                    <FontAwesomeIcon icon={faExclamationTriangle}/> {errors.branchcode.message}
                                                </Form.Control.Feedback>
                                            }
                                        </Form.Group>
                            }
                        </div>

                        <div className="col-12 col-sm-6 col-md-4">
                            <Form.Group controlId="containerno">
                                <Form.Label>Container number:</Form.Label>
                                <Form.Control
                                    name="containerno"
                                    ref={register({required: "You must enter a container number"})}
                                    isInvalid={!!errors.containerno}
                                    defaultValue={""}/>
                                {
                                    errors.containerno &&
                                    <Form.Control.Feedback type="invalid">
                                        <FontAwesomeIcon icon={faExclamationTriangle}/> {errors.containerno.message}
                                    </Form.Control.Feedback>
                                }
                            </Form.Group>
                        </div>

                        <div className="col-12">
                            <Form.Group controlId="submit">
                                <FormButton type="submit" variant="primary"
                                            loading={formSubmitting}>Submit</FormButton>
                            </Form.Group>
                        </div>

                    </div>
                </form>

                {
                    !!enquiryData &&
                    <div className="row">
                        <div className="col-12">
                            {
                                !enquiryData.length ?
                                    <Alert variant="warning">No information is available for this container number</Alert>
                                    :
                                    <>
                                        <Table striped responsive>
                                            <thead>
                                            <tr>
                                                <th>Container number</th>
                                                <th>Depot</th>
                                                <th>Owner</th>
                                                <th>Lessee</th>
                                                <th>ISO Code</th>
                                                <th>Size Code</th>
                                                <th>Height Code</th>
                                                <th>Type Code</th>
                                                <th>Rate Code</th>
                                                <th>Quality Code</th>
                                                <th>Don't Accept</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                enquiryData.map(container => (
                                                    <tr>
                                                        <td>{container.containerno}</td>
                                                        <td>{getBranchName(branchesData, container.branchcode)}</td>
                                                        <td>{container.owner}</td>
                                                        <td>{container.lessee}</td>
                                                        <td>{container.isocode}</td>
                                                        <td>{container.sizecode}</td>
                                                        <td>{container.heightcode}</td>
                                                        <td>{container.typecode}</td>
                                                        <td>{container.ratecode}</td>
                                                        <td>{container.qualitycode}</td>
                                                        <td>{container.dontaccept}</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                    </>
                            }
                        </div>
                    </div>
                }

            </div>
        </>
    );
};

export default ContainerEnquiryPage;
